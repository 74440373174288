import React, { useEffect, useState, useMemo, useCallback } from 'react'

import months from './months'

import { firestore, storage } from '../../../../lib/firebase'
import Structure from '../Structure'
import Styling from './Styling'

import ContentHeader from './ContentHeader'
import CreateEventModal from './CreateEventModal'
import ActModal from './ActModal'
import EventList from './EventList'
import Event from './Event'

function Events() {
  const { db, collection, getDocs, setDoc, doc, deleteDoc, where, query } = firestore
  const { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } = storage

  const [limitEvents, setLimitEvents] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [createModalShow, setCreateModalShow] = useState(false)
  const [actModalShow, setActModalShow] = useState(false)

  const [currentAct, setCurrentAct] = useState(undefined)
  const [events, setEvents] = useState([])
  const [eventId, setEventId] = useState()

  const [label, setLabel] = useState('')
  const [date, setDate] = useState('')
  const [extra, setExtra] = useState('')
  const [extraDate, setExtraDate] = useState('')
  const [tour, setTour] = useState('')
  const [admission, setAdmission] = useState('')
  const [begin, setBegin] = useState('')
  const [online, setOnline] = useState(false)
  const [acts, setActs] = useState([])
  const [volta, setVolta] = useState(false)
  const [garden, setGarden] = useState(false)
  const [victoria, setVictoria] = useState(false)
  const [htmlContent, setHtmlContent] = useState('')
  const [eventFacebook, setEventFacebook] = useState('')
  const [tickets, setTickets] = useState('')
  const [organizerName, setOrganizerName] = useState('')
  const [organizerUrl, setOrganizerUrl] = useState('')
  const [prePrice, setPrePrice] = useState('')
  const [price, setPrice] = useState('')
  const [previousId, setPreviousId] = useState('')
  const [previousSlug, setPreviousSlug] = useState('')
  const [organizers, setOrganizers] = useState([])

  const ready = useMemo(
    () => label && date && (volta || garden || victoria) && acts && acts.length > 0,
    [label, date, volta, garden, victoria, acts],
  )

  const labels = useMemo(() => {
    const labs = ['Vergangen']
    events
      .map((event) => event.monthYear)
      .forEach((monthYear) => {
        if (labs.indexOf(monthYear) === -1) {
          labs.push(monthYear)
        }
      })
    return labs
  }, [events])

  const getEventsMonthYear = useCallback((date) => {
    if (Date.parse(date) - Date.parse(new Date()) + 86400000 < 0) {
      return 'Vergangen'
    }
    const year = date.split('-')[0]
    const month = months[parseInt(date.split('-')[1], 10)]
    return `${month} ${year}`
  }, [])

  const getEvents = useCallback(async () => {
    const res = []
    var date = new Date()
    date.setFullYear(date.getFullYear() - 1)
    const lastYear = date.toISOString().split('T')[0]
    // console.log(lastYear)

    const limited = query(collection(db, 'events'), where('date', '>', lastYear))
    const all = collection(db, 'events')
    const q = limitEvents ? limited : all
    //await getDocs(collection(db, 'events')).then((querySnapshot) =>
    await getDocs(q).then((querySnapshot) =>
      querySnapshot.forEach((doc) => {
        const data = {
          date: '', // immer leer initialisieren
          begin: '',
          online: false,
          ...doc.data(),
        }
        res.push({ id: doc.id, ...data })
      }),
    )
    return res
      .sort((a, b) =>
        a.date === b.date ? (a?.begin || '').localeCompare(b?.begin || '') : a.date.localeCompare(b.date),
      )
      .map((event) => ({
        ...event,
        monthYear: getEventsMonthYear(event.date),
      }))
  }, [collection, db, getDocs, getEventsMonthYear, limitEvents, query, where])

  const getOrganizers = useCallback(async () => {
    getDocs(collection(db, 'organizers'))
      .then((querySnapshot) => {
        const res = []
        querySnapshot.forEach((doc) => {
          res.push({ name: doc.id, ...doc.data() })
        })
        return res.sort((a, b) => a.name.localeCompare(b.name))
      })
      .then(setOrganizers)
  }, [collection, db, getDocs])

  const save = useCallback(
    async (props) => {
      let toSave = {
        label,
        date,
        online,
        tour,
        organizerName,
        organizerUrl,
        eventFacebook,
        tickets,
        extra,
        extraDate,
        admission,
        begin,
        prePrice,
        price,
        htmlContent,
        volta,
        garden,
        victoria,
        acts,
        previousId,
        previousSlug,
      }
      if (props?.what) {
        toSave = { ...toSave, ...props.what }
      }
      toSave.lastUpdate = new Date().toISOString()
      Object.keys(toSave).forEach((key) => {
        if (toSave[key] === null || toSave[key] === undefined) {
          delete toSave[key]
        }
      })
      // console.log('gespeichert', toSave)
      await setDoc(doc(db, 'events', eventId), toSave, { merge: true })
      setEvents(await getEvents())
    },
    [
      label,
      date,
      online,
      tour,
      organizerName,
      organizerUrl,
      eventFacebook,
      tickets,
      extra,
      extraDate,
      admission,
      begin,
      prePrice,
      price,
      htmlContent,
      volta,
      garden,
      victoria,
      acts,
      previousId,
      previousSlug,
      setDoc,
      doc,
      db,
      eventId,
      getEvents,
    ],
  )

  const addAct = useCallback(async () => {
    await save()
    setCurrentAct(acts?.length || 0)
    setActModalShow(true)
  }, [acts?.length, save])

  const upload = useCallback(
    (e, actindex) => {
      const file = e.target.files[0]
      const metadata = { contentType: 'image/jpeg' }
      const reference = `images/${eventId}/${file.name.replace(/[^a-zA-Z0-9]/g, '')}`

      /* console.log(actindex, file, metadata, reference)
      return */
      const storageRef = ref(getStorage(), reference)
      const uploadTask = uploadBytesResumable(storageRef, file, metadata)

      uploadTask.on(
        'state_changed',
        () => {},
        (error) => console.error(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((src) => {
            const newActs = acts
            newActs[actindex].images = [{ src, reference }]
            save({ what: { acts: newActs } })
          })
        },
      )
    },
    [acts, eventId, getDownloadURL, getStorage, ref, save, uploadBytesResumable],
  )

  const delImage = useCallback(
    async (idx, actindex) => {
      const img = acts[actindex].images
      const { reference } = img[0]
      const storage = getStorage()
      const desertRef = ref(storage, reference)
      // setLoading(true)
      deleteObject(desertRef)
        .then(() => {})
        .catch(() => {
          console.error('Could not delete image with reference', reference)
        })

      const s = acts
      s[actindex].images = []
      save({ what: { acts: s } })
    },
    [acts, deleteObject, getStorage, ref, save],
  )

  const editAct = useCallback(
    (sort) => {
      save()
      setCurrentAct(sort)
      setActModalShow(true)
    },
    [save],
  )

  const moveAct = useCallback(
    (actindex, dir) => {
      const newActs = [...acts]
      if (dir === 'up') {
        newActs[actindex - 1] = acts[actindex]
        newActs[actindex] = acts[actindex - 1]
      } else {
        newActs[actindex + 1] = acts[actindex]
        newActs[actindex] = acts[actindex + 1]
      }
      save({ what: { acts: newActs } })
    },
    [acts, save],
  )

  const deleteAct = useCallback(
    (actindex) => {
      const newActs = acts
      newActs.splice(actindex, 1)
      save({ what: { acts: newActs } })
    },
    [acts, save],
  )

  const mergeActs = (act, add = false) => {
    const newActs = acts
    if (newActs[currentAct] && !add) {
      newActs[currentAct] = { ...newActs[currentAct], ...act }
    } else {
      newActs.push(act)
    }
    save({ what: { acts: newActs } })
  }

  const publish = useCallback(
    (check) => {
      setOnline(check)
      save({ what: { online: check } })
    },
    [save],
  )

  const createEvent = useCallback(
    async (l, d, id) => {
      try {
        await setDoc(doc(db, 'events', id), { label: l, date: d }, { merge: true })
        setEvents(await getEvents())
        setEventId(id)
      } catch (e) {
        console.error('Error adding document: ', e)
      }
    },
    [db, doc, getEvents, setDoc],
  )

  const deleteEvent = useCallback(async () => {
    if (
      !ready ||
      window.confirm(
        `Bist du sicher, dass du folgendes Event löschen willst?\n >>> ${label} <<<\nDies kann nicht rückgängig gemacht werden!`,
      )
    ) {
      const storage = getStorage()
      if ((acts || []).length > 0) {
        acts.forEach((act) => {
          if ((act?.images || []).length > 0) {
            act.images.forEach(({ reference }) => deleteObject(ref(storage, reference)))
          }
        })
      }
      await deleteDoc(doc(db, 'events', eventId))
      const nextEvents = await getEvents()
      setEvents(nextEvents)
      setEventId(undefined)
    }
  }, [acts, db, deleteDoc, deleteObject, doc, eventId, getEvents, getStorage, label, ready, ref])

  const copyEvent = useCallback(async () => {
    if (!ready || window.confirm(`Bist du sicher, dass du folgendes Event duplizieren willst?\n >>> ${label} <<<`)) {
      const copiedEventId = `${new Date().toISOString().slice(0, 10)}-${eventId.slice(11)}-copy`
      const copiedEvent = events.find((x) => x.id === eventId)
      delete copiedEvent.id
      copiedEvent.copiedFrom = eventId
      await setDoc(doc(db, 'events', copiedEventId), copiedEvent)
      const nextEvents = await getEvents()
      setEvents(nextEvents)
      setEventId(copiedEventId)
    }
  }, [db, doc, eventId, events, getEvents, label, ready, setDoc])

  useEffect(() => {
    const event = events.find((ev) => ev.id === eventId)
    if (event) {
      setLabel(event.label || '')
      setExtra(event.extra || '')
      setExtraDate(event.extraDate || '')
      setTour(event.tour || '')
      setDate(event.date || '')
      setAdmission(event.admission || '')
      setBegin(event.begin || '')
      setOnline(event.online || false)
      setVolta(event.volta || false)
      setGarden(event.garden || false)
      setVictoria(event.victoria || false)
      setHtmlContent(event.htmlContent || '')
      setOrganizerName(event.organizerName || '')
      setOrganizerUrl(event.organizerUrl || '')
      setPrePrice(event.prePrice || '')
      setPrice(event.price || '')
      setTickets(event.tickets || '')
      setEventFacebook(event.eventFacebook || '')
      setActs(event.acts || [])
      setPreviousId(event.previousId || '')
      setPreviousSlug(event.previousSlug || '')
    }
  }, [events, eventId])

  useEffect(() => {
    const init = async () => {
      setEvents(await getEvents())
      setIsLoading(false)
      getOrganizers()
    }
    init()
  }, [getEvents, getOrganizers])

  return isLoading ? null : (
    <>
      <Structure>
        <ContentHeader
          eventId={eventId}
          deleteEvent={deleteEvent}
          copyEvent={copyEvent}
          setCreateModalShow={setCreateModalShow}
          addAct={addAct}
        />
        <Structure.Content>
          <Styling>
            <EventList
              events={events}
              labels={labels}
              eventId={eventId}
              setEventId={setEventId}
              limitEvents={limitEvents}
              setLimitEvents={setLimitEvents}
            />
            <Event
              eventId={eventId}
              label={label}
              date={date}
              volta={volta}
              garden={garden}
              victoria={victoria}
              online={online}
              ready={ready}
              extra={extra}
              extraDate={extraDate}
              tour={tour}
              admission={admission}
              organizers={organizers}
              organizerName={organizerName}
              begin={begin}
              eventFacebook={eventFacebook}
              prePrice={prePrice}
              tickets={tickets}
              price={price}
              htmlContent={htmlContent}
              acts={acts}
              setLabel={setLabel}
              setDate={setDate}
              setVolta={setVolta}
              setGarden={setGarden}
              setVictoria={setVictoria}
              publish={publish}
              setExtra={setExtra}
              setExtraDate={setExtraDate}
              setTour={setTour}
              setAdmission={setAdmission}
              setOrganizerName={setOrganizerName}
              setOrganizerUrl={setOrganizerUrl}
              setBegin={setBegin}
              setEventFacebook={setEventFacebook}
              setPrePrice={setPrePrice}
              setTickets={setTickets}
              setPrice={setPrice}
              setHtmlContent={setHtmlContent}
              upload={upload}
              delImage={delImage}
              editAct={editAct}
              deleteAct={deleteAct}
              moveAct={moveAct}
              mergeActs={mergeActs}
              save={save}
              previousId={previousId}
              setPreviousId={setPreviousId}
              previousSlug={previousSlug}
              setPreviousSlug={setPreviousSlug}
            />
          </Styling>
        </Structure.Content>
      </Structure>
      <CreateEventModal show={createModalShow} createEvent={createEvent} onHide={() => setCreateModalShow(false)} />
      <ActModal
        acts={acts}
        currentAct={currentAct}
        show={actModalShow}
        mergeActs={mergeActs}
        onHide={() => setActModalShow(false)}
      />
    </>
  )
}

export default Events
