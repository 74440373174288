import styled from 'styled-components'
import { Link } from 'react-router-dom'

import colors from '../../../lib/colors'

export const Image = styled(Link)`
  text-decoration: none;

  &:nth-of-type(1) {
    grid-row: 1 / 3;
    grid-column: 1 / 3;
    animation: flicker 2.6s infinite;
    animation-delay: 0s;
    &:hover {
      opacity: 1 !important;
      animation-play-state: paused;
    }
  }
  &:nth-of-type(2) {
    grid-row: 1 / 3;
    grid-column: 3 / 5;
    animation: flicker 2.6s infinite;
    animation-delay: 0.2s;
    &:hover {
      opacity: 1 !important;
      animation-play-state: paused;
    }
  }

  &:nth-of-type(3) {
    grid-row: 2 / 4;
    grid-column: 2 / 4;
    animation: flicker 2.6s infinite;
    animation-delay: 0.4s;
    &:hover {
      opacity: 1 !important;
      animation-play-state: paused;
    }
  }

  &:nth-of-type(4) {
    grid-row: 3 / 5;
    grid-column: 1 / 3;
    animation: flicker 2.6s infinite;
    animation-delay: 0.6s;
    &:hover {
      opacity: 1 !important;
      animation-play-state: paused;
    }
  }
  &:nth-of-type(5) {
    grid-row: 3 / 5;
    grid-column: 3 / 5;
    animation: flicker 2.6s infinite;
    animation-delay: 0.8s;
    &:hover {
      opacity: 1 !important;
      animation-play-state: paused;
    }
  }

  &:nth-of-type(6) {
    grid-row: 4 / 6;
    grid-column: 2 / 4;
    animation: flicker 2.6s infinite;
    animation-delay: 1s;
    &:hover {
      opacity: 1 !important;
      animation-play-state: paused;
    }
  }

  &:nth-of-type(7) {
    grid-row: 5 / 7;
    grid-column: 1 / 3;
    animation: flicker 2.6s infinite;
    animation-delay: 1.2s;
    &:hover {
      opacity: 1 !important;
      animation-play-state: paused;
    }
  }
  &:nth-of-type(8) {
    grid-row: 5 / 7;
    grid-column: 3 / 5;
    animation: flicker 2.6s infinite;
    animation-delay: 1.4s;
    &:hover {
      opacity: 1 !important;
      animation-play-state: paused;
    }
  }

  &:nth-of-type(9) {
    display: none;
    animation: flicker 2.6s infinite;
    animation-delay: 1.6s;
    &:hover {
      opacity: 1 !important;
      animation-play-state: paused;
    }
  }
  &:nth-of-type(10) {
    display: none;
    animation: flicker 2.6s infinite;
    animation-delay: 1.8s;
    &:hover {
      opacity: 1 !important;
      animation-play-state: paused;
    }
  }

  &:nth-of-type(11) {
    display: none;
    animation: flicker 2.6s infinite;
    animation-delay: 2s;
    &:hover {
      opacity: 1 !important;
      animation-play-state: paused;
    }
  }
  &:nth-of-type(12) {
    display: none;
    animation: flicker 2.6s infinite;
    animation-delay: 2.2s;
    &:hover {
      opacity: 1 !important;
      animation-play-state: paused;
    }
  }

  &:nth-of-type(13) {
    display: none;
    animation: flicker 2.6s infinite;
    animation-delay: 2.4s;
    &:hover {
      opacity: 1 !important;
      animation-play-state: paused;
    }
  }

  @media (min-width: 640px) {
    &:nth-of-type(1) {
      grid-row: 1 / 3;
      grid-column: 2 / 4;
    }
    &:nth-of-type(2) {
      grid-row: 1 / 3;
      grid-column: 4 / 6;
    }
    &:nth-of-type(3) {
      grid-row: 1 / 3;
      grid-column: 6 / 8;
    }

    &:nth-of-type(4) {
      grid-row: 2 / 4;
      grid-column: 1 / 3;
    }
    &:nth-of-type(5) {
      grid-row: 2 / 4;
      grid-column: 3 / 5;
    }
    &:nth-of-type(6) {
      grid-row: 2 / 4;
      grid-column: 5 / 7;
    }
    &:nth-of-type(7) {
      grid-row: 2 / 4;
      grid-column: 7 / 9;
    }

    &:nth-of-type(8) {
      grid-row: 3 / 5;
      grid-column: 2 / 4;
    }
    &:nth-of-type(9) {
      grid-row: 3 / 5;
      grid-column: 4 / 6;
      display: block;
    }
    &:nth-of-type(10) {
      grid-row: 3 / 5;
      grid-column: 6 / 8;
      display: block;
    }

    &:nth-of-type(11) {
      grid-row: 4 / 6;
      grid-column: 3 / 5;
      display: block;
    }
    &:nth-of-type(12) {
      grid-row: 4 / 6;
      grid-column: 5 / 7;
      display: block;
    }

    &:nth-of-type(13) {
      grid-row: 5 / 7;
      grid-column: 4 / 6;
      display: block;
    }
  }

  @keyframes flicker {
    0% {
      opacity: 0.8;
      transform: scale(1);
    }
    50% {
      opacity: 1;
      transform: scale(1.02);
    }
    100% {
      opacity: 0.8;
      transform: scale(1);
    }
  }
`

export const InnerImage = styled.div`
  position: relative;
  background-color: #000;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: calc(var(--m) - var(--s) * 0.5);
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
  width: 45.5vw;
  height: 45.5vw;

  @media (min-width: 640px) {
    width: 22.1vw;
    height: 22.1vw;
  }

  @media (min-width: 768px) {
    width: 22.5vw;
    height: 22.5vw;
  }

  @media (min-width: 1000px) {
    width: 23vw;
    height: 23vw;
  }

  @media (min-width: 1266px) {
    width: 300px;
    height: 300px;
  }
`

export const Inner = styled.div`
  display: grid;
  gap: 20px;
  padding: 5px;
`

export const ExtraContent = styled.div`
  color: ${(props) => colors[props['data-extra']] || '#78cdd2'};
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;

  @media (min-width: 640px) {
    font-size: 13px;
  }
  @media (min-width: 768px) {
    font-size: 15px;
  }
  @media (min-width: 900px) {
    font-size: 16px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75), -1px -1px 2px rgba(0, 0, 0, 0.75), 1px -1px 2px rgba(29, 79, 45, 0.75),
    -1px 1px 2px rgba(0, 0, 0, 0.75);
`

export const TileContent = styled.div`
  position: relative;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  text-align: center;

  color: #ffffff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75), -1px -1px 2px rgba(0, 0, 0, 0.75), 1px -1px 2px rgba(29, 79, 45, 0.75),
    -1px 1px 2px rgba(0, 0, 0, 0.75);

  font-size: 16px;
  font-weight: 700;
  line-height: 28px;

  @media (min-width: 640px) {
    font-size: 13px;
  }
  @media (min-width: 768px) {
    font-size: 15px;
  }
  @media (min-width: 900px) {
    font-size: 16px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

export default styled.div`
  background-color: white;
  margin: auto;
  width: 100%;
  margin-top: 280px;
  overflow: hidden;
  animation: ${(props) => (props['data-animation'] ? 'fadeIn 2s ease-in-out' : 'none')};

  @media (min-width: 640px) {
    margin-top: 400px;
    max-width: 1266px;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: rotate3d(0, 1, 0, 90deg);
    }
    100% {
      opacity: 1;
      transform: rotate3d(0);
    }
  }
`
