/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import 'react-quill/dist/quill.snow.css'
import { Form, Button, ButtonGroup, Row, Col, InputGroup } from 'react-bootstrap'

import { MdDelete as IconDelete } from 'react-icons/md'

import {
  IoRocket as IconMainAct,
} from 'react-icons/io5'


import { BsImage as IconImage } from 'react-icons/bs'

import Styling from './Styling'

export default function Act({ pic, upload, delImage, deleteAct, actindex }) {
  
  return (
    <>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Row>
            <Col>
              <ButtonGroup className="my-2 w-100">
                <Button variant="danger" onClick={() => deleteAct(actindex)} disabled={!pic}>
                  löschen
                </Button>
              </ButtonGroup>
              <InputGroup className="my-2">
                <InputGroup.Text style={{ width: '150px' }}>
                  <IconMainAct />
                  &nbsp;Type
                </InputGroup.Text>
                <Form.Control value='Event Picture' disabled />
              </InputGroup>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          {(pic?.images || []).length === 0 ? (
            <>
              <Row>
                <Col>
                  <InputGroup className="my-2">
                    <InputGroup.Text style={{ width: '80px' }}>
                      <IconImage />
                      &nbsp;Bild
                    </InputGroup.Text>
                    <Form.Control type="file" onChange={(e) => upload(e, actindex)} />
                  </InputGroup>
                </Col>
              </Row>
            </>
          ) : (
            <Styling.Images>
              {pic.images.map(({ src }) => (
                <Styling.Image key={src} src={src} style={{ height: '300px' }}>
                  <div style={{ textAlign: 'right' }}>
                    <Button size="sm" variant="danger" onClick={() => delImage(0, actindex)}>
                      <IconDelete />
                    </Button>
                  </div>
                </Styling.Image>
              ))}
            </Styling.Images>
          )}
        </Col>
      </Row>
    </>
  )
}
