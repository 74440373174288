import React from 'react'
import Styling from './Styling'
import Structure from '../Structure'

export default function Dashboard() {
  return (
    <Structure>
      <Structure.ContentHeader>
        <Structure.Section>
          Willkommen
        </Structure.Section>
        <Structure.Interaction>
          {' '}
        </Structure.Interaction>
      </Structure.ContentHeader>
      <Structure.Content>
      <Styling>
          <Styling.Card>
            Dieser Account hat noch keine Rechte im System und muss erst durch einen der Admins freigeschaltet werden.
          </Styling.Card>
        </Styling>
      </Structure.Content>
    </Structure>
  )
}