import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Button, ButtonGroup, Container, Col, Form, InputGroup, Row } from 'react-bootstrap'

import { IoEarth as IconInternal, IoEye as IconOnline } from 'react-icons/io5'

import { BsCalendarEvent as IconCalendar, BsArchiveFill as IconEvent } from 'react-icons/bs'

import Logo from '../../../shared/Logo'
import Styling from './Styling'
import Picture from './Picture'

function NewsItem({
  newsId,
  label,
  date,
  dateEnd,
  volta,
  garden,
  victoria,
  online,
  ready,
  htmlContent,
  setLabel,
  setDate,
  setDateEnd,
  setVolta,
  setGarden,
  setVictoria,
  publish,
  setHtmlContent,
  upload,
  delImage,
  save,
  images,
  setImages,
}) {
  return !newsId ? null : (
    <Styling.NewsCard>
      <Container style={{ height: 'calc(100vh - 318px)', overflow: 'auto' }} className="my-2">
        <Row>
          <Col xs={12}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconEvent />
                &nbsp;Label *
              </InputGroup.Text>
              <Form.Control placeholder="" value={label} onChange={(e) => setLabel(e.target.value)} />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconCalendar />
                &nbsp;Datum von *
              </InputGroup.Text>
              <Form.Control
                type="date"
                value={date}
                required
                onChange={(e) => setDate(e.target.value)}
                min={new Date().toISOString().substring(0, 10)}
              />
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconCalendar />
                &nbsp;Datum bis
              </InputGroup.Text>
              <Form.Control
                type="date"
                value={dateEnd}
                required
                onChange={(e) => setDateEnd(e.target.value)}
                min={new Date().toISOString().substring(0, 10)}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>Wird kein Enddatum angegeben, so wird die Kachel dauerhaft angezeigt.</Col>
        </Row>
        <Row>
          <Col>
            <ButtonGroup className="my-2 w-100">
              <Button variant={`${volta ? '' : 'outline-'}dark`} onClick={() => setVolta((current) => !current)}>
                Club Volta
              </Button>
              <Button variant={`${garden ? '' : 'outline-'}dark`} onClick={() => setGarden((current) => !current)}>
                Biergarten
              </Button>
              <Button variant={`${victoria ? '' : 'outline-'}dark`} onClick={() => setVictoria((current) => !current)}>
                Carlswerk Victoria
              </Button>
            </ButtonGroup>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconOnline />
                &nbsp;News
              </InputGroup.Text>
              <div className="px-3" style={{ marginTop: '-8px' }}>
                <Form.Check
                  type="switch"
                  label="Online anzeigen"
                  onChange={(e) => publish(e.target.checked)}
                  checked={online}
                  disabled={!ready}
                  className="mt-3"
                />
              </div>
            </InputGroup>
          </Col>
          <Col xs={6}>&nbsp;</Col>
        </Row>

        <Row>
          <Col className="my-2">
            <ReactQuill theme="snow" value={htmlContent} onChange={setHtmlContent} />
          </Col>
        </Row>

        {images.length > 0 ? (
          <Picture images={images} upload={upload} delImage={delImage} />
        ) : (
          <ButtonGroup className="my-2 w-100">
            <Button variant="primary" onClick={() => setImages([{ type: 'newspicture', sort: -1 }], true)}>
              Extra Eventbild anlegen
            </Button>
          </ButtonGroup>
        )}
      </Container>
      <Container>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col xs={9}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconInternal />
                &nbsp;
                <a target="_blank" rel="noreferrer" href={`${window.location.origin}/news/${newsId}`}>
                  Preview
                </a>
              </InputGroup.Text>
              <Form.Control value={`/news/${newsId}`} onChange={() => {}} disabled />
            </InputGroup>
          </Col>
          <Col xs={3}>
            <Button variant={!ready ? 'danger' : 'success'} onClick={save} className="my-2 w-100" disabled={!ready}>
              <Logo volta={volta} garden={garden} victoria={victoria} color="#FFFFFF" width={27} height={22} /> Save
            </Button>
          </Col>
        </Row>
      </Container>
    </Styling.NewsCard>
  )
}

export default NewsItem
