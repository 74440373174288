import { useContext, useState, createContext } from 'react'

export const MenuContext = createContext()

export const MenuProvider = ({ children }) => {
  
  const [loading, setLoading] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [error, setError] = useState('')
  
  const value = {
    error,
    loading,
    menuOpen,
    setLoading,
    setMenuOpen,
    setError,
  }

  return (
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  )
}

export default function useMenu(){
  return useContext(MenuContext)
}
  