// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import {
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  updateEmail,
  getAuth,
  signOut,
} from 'firebase/auth'

import { uploadBytesResumable, getDownloadURL, deleteObject, getStorage, ref } from 'firebase/storage'

import {
  serverTimestamp,
  getFirestore,
  collection,
  onSnapshot,
  startAfter,
  deleteDoc,
  getDocs,
  getDoc,
  orderBy,
  setDoc,
  addDoc,
  query,
  limit,
  where,
  doc,
} from 'firebase/firestore'

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig, '[DEFAULT]')

// const analytics = getAnalytics(app);

const firestore = {
  db: getFirestore(),
  serverTimestamp,
  onSnapshot,
  collection,
  startAfter,
  deleteDoc,
  getDocs,
  getDoc,
  orderBy,
  setDoc,
  addDoc,
  query,
  where,
  limit,
  doc,
}

const storage = {
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  getStorage,
  ref,
}

const auth = {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
}

export {
  auth,
  firestore,
  storage,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  updateEmail,
  signOut,
  getAuth,
}

export default app
