/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import 'react-quill/dist/quill.snow.css'
import { Button, Container, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'

import {
  IoPerson as IconOrganizer,
  IoLogoFacebook as IconFacebook,
  IoHome as IconWebsite,
  IoLogoYoutube as IconYoutube,
  IoLogoTiktok as IconTiktok,
  IoRocket as IconMainAct,
} from 'react-icons/io5'

import { AiFillInstagram as IconInstagram } from 'react-icons/ai'

function ActModal({ onHide, show, acts, currentAct, mergeActs }) {
  const act = acts[currentAct]
  const [name, setName] = useState(act?.name || '')
  const [website, setWebsite] = useState(act?.website || '')
  const [tiktok, setTiktok] = useState(act?.tiktok || '')
  const [facebook, setFacebook] = useState(act?.facebook || '')
  const [instagram, setInstagram] = useState(act?.instagram || '')
  const [youtube, setYoutube] = useState(act?.youtube || '')
  const [type, setType] = useState(act?.type || '')

  const saveAct = (e) => {
    e.preventDefault()
    mergeActs({
      name,
      website,
      tiktok,
      facebook,
      instagram,
      youtube,
      type,
      sort: currentAct || 0,
    })
    onHide()
  }

  useEffect(() => {
    if (show) {
      if (!act) {
        setName('')
        setWebsite('')
        setTiktok('')
        setFacebook('')
        setInstagram('')
        setYoutube('')
        setType('')
      } else {
        setName(act?.name || '')
        setWebsite(act?.website || '')
        setTiktok(act?.tiktok || '')
        setFacebook(act?.facebook || '')
        setInstagram(act?.instagram || '')
        setYoutube(act?.youtube || '')
        setType(act?.type || '')
      }
    }
  }, [show])

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-create-event" centered>
      <form onSubmit={saveAct}>
        <Modal.Header closeButton>
          {!acts[currentAct] ? (
            <Modal.Title id="contained-modal-create-event">Act hinzufügen</Modal.Title>
          ) : (
            <Modal.Title id="contained-modal-create-event">Act ändern</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col>
                <InputGroup className="my-2">
                  <InputGroup.Text style={{ width: '150px' }}>
                    <IconMainAct />
                    &nbsp;Type
                  </InputGroup.Text>
                  <Form.Select value={type} onChange={(e) => setType(e.target.options[e.target.selectedIndex].value)}>
                    <option value="">wähle</option>
                    <option value="main">Main Act</option>
                    <option value="special">Special Guest</option>
                    <option value="support">Support Act</option>
                  </Form.Select>
                </InputGroup>
              </Col>
            </Row>
            {['main', 'support', 'special'].includes(type) && (
              <>
                <hr />
                <Row>
                  <Col>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '150px' }}>
                        <IconOrganizer />
                        &nbsp;Name
                      </InputGroup.Text>
                      <Form.Control required value={name} onChange={(e) => setName(e.target.value)} />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '150px' }}>
                        <IconWebsite />
                        &nbsp;Website
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="https://"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '150px' }}>
                        <IconFacebook />
                        &nbsp;facebook
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="https://"
                        value={facebook}
                        onChange={(e) => setFacebook(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '150px' }}>
                        <IconInstagram />
                        &nbsp;Instagram
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="https://"
                        value={instagram}
                        onChange={(e) => setInstagram(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '150px' }}>
                        <IconTiktok />
                        &nbsp;Tiktok
                      </InputGroup.Text>
                      <Form.Control placeholder="https://" value={tiktok} onChange={(e) => setTiktok(e.target.value)} />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '150px' }}>
                        <IconYoutube />
                        &nbsp;Youtube
                      </InputGroup.Text>
                      <Form.Control
                        placeholder="https://"
                        value={youtube}
                        onChange={(e) => setYoutube(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={onHide}>
            abbrechen
          </Button>
          <Button variant="primary" size="sm" type="submit" disabled={!type}>
            speichern
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default ActModal
