import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'

import {
  IoHome as IconWebsite,
  IoTicketSharp as IconTickets,
  IoLogoYoutube as IconYoutube,
  IoLogoFacebook as IconFacebook,
  IoLogoTiktok as IconTiktok,
} from 'react-icons/io5'

import { AiFillInstagram as IconInstagram } from 'react-icons/ai'

import getEventDate from '../../../lib/getEventDate'
import getExtra from '../../../lib/getExtra'

import Extra from '../../shared/Extra'
import Club from '../../shared/Club'
import { firestore } from '../../../lib/firebase'
import Wrapper, {
  Container,
  Image,
  Grid,
  TourName,
  EventName,
  SupportArtist,
  Description,
  ExtraInfo,
  DateClub,
  EventDate,
  ClubArea,
  TimeAdmission,
  TimeBegin,
  PresalePrice,
  BoxOfficePrice,
  Organizer,
  FacebookAttend,
  TicketsLink,
  Supports,
  Support,
  SupportImage,
  SupportName,
  SupportSocials,
} from './Styling'

function isValidUrl(string) {
  try {
    new URL(string)
    return true
  } catch (err) {
    return false
  }
}

const Event = ({
  label,
  date,
  tour,
  organizerName,
  organizerUrl,
  eventFacebook,
  tickets,
  extra,
  extraDate,
  admission,
  begin,
  prePrice,
  price,
  htmlContent,
  volta,
  garden,
  victoria,
  acts,
}) => {
  const ep = acts.filter(({ type }) => type === 'eventpicture')
  const ma = acts.filter(({ type }) => type === 'main')
  const mainacts = ma.length > 0 ? ma : acts
  const specials = acts.filter(({ type }) => type === 'special')
  const supports = acts.filter(({ type }) => type === 'support')
  const image = ep?.[0]?.images?.[0]?.src || mainacts?.[0]?.images?.[0]?.src || '' || ''

  //const main = mainacts.reduce((acc, act) => [...acc, act.name], []).join(', ')
  const special = specials.reduce((acc, act) => [...acc, act.name], []).join(', ')
  const support = supports.reduce((acc, act) => [...acc, act.name], []).join(', ')

  return (
    <>
      <Image src={image} alt={label}>
        <div />
      </Image>

      <Grid>
        <div>
          <ExtraInfo>
            <Extra extra={getExtra(extra, extraDate)} />
            &nbsp;
          </ExtraInfo>
          <EventName>{label}</EventName>
          <TourName>{tour}</TourName>
          {specials.length > 0 && (
            <SupportArtist>
              {`Special Guest${specials.length > 1 ? 's' : ''}: `}
              {special}
            </SupportArtist>
          )}
          {supports.length > 0 && (
            <SupportArtist>
              {`Support${supports.length > 1 ? 's' : ''}: `}
              {support}
            </SupportArtist>
          )}
          <Description>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </Description>
        </div>

        <div style={{ paddingTop: '25px' }}>
          <DateClub>
            <EventDate>{getEventDate(date)}</EventDate>
            <ClubArea>
              <Club volta={volta} garden={garden} victoria={victoria} />
            </ClubArea>
          </DateClub>
          {tickets && (
            <TicketsLink>
              <a href={tickets} target="_blank" rel="noopener noreferrer">
                <IconTickets />
                Tickets
              </a>
            </TicketsLink>
          )}
          {admission && (
            <TimeAdmission>
              <strong>Einlass:</strong>
              <div>{admission} Uhr</div>
            </TimeAdmission>
          )}
          {begin && (
            <TimeBegin>
              <strong>Beginn:</strong>
              <div>{begin} Uhr</div>
            </TimeBegin>
          )}
          {prePrice && (
            <PresalePrice>
              <strong>Vorverkauf:</strong>
              <div>{prePrice}</div>
            </PresalePrice>
          )}
          {price && (
            <BoxOfficePrice>
              <strong>Abendkasse:</strong>
              <div>{price}</div>
            </BoxOfficePrice>
          )}
          {organizerName && (
            <Organizer>
              <strong>Veranstalter:</strong>
              <div>
                {organizerUrl && isValidUrl(organizerUrl) ? (
                  <a href={organizerUrl} target="_blank" rel="noopener noreferrer">
                    {organizerName}
                  </a>
                ) : (
                  organizerName
                )}
              </div>
            </Organizer>
          )}
          {eventFacebook && (
            <FacebookAttend>
              <a href={eventFacebook} target="_blank" rel="noopener noreferrer">
                <IconFacebook />
                Teilnehmen
              </a>
            </FacebookAttend>
          )}
        </div>
        <div>
          {(acts || []).length > 0 && (
            <Supports>
              {acts
                .filter((a) => a.type !== 'eventpicture')
                .map(({ name, images, website, facebook, instagram, tiktok, youtube }, idx) => (
                  <Support key={`${idx}-${name}`}>
                    <SupportImage>
                      <div style={{ backgroundImage: `url(${images?.[0]?.src})` }} />
                    </SupportImage>
                    <SupportName>{name}</SupportName>
                    <SupportSocials>
                      {website && (
                        <a href={website} target="_blank" rel="noopener noreferrer">
                          <IconWebsite />
                        </a>
                      )}
                      {facebook && (
                        <a href={facebook} target="_blank" rel="noopener noreferrer">
                          <IconFacebook />
                        </a>
                      )}
                      {instagram && (
                        <a href={instagram} target="_blank" rel="noopener noreferrer">
                          <IconInstagram />
                        </a>
                      )}
                      {tiktok && (
                        <a href={tiktok} target="_blank" rel="noopener noreferrer">
                          <IconTiktok />
                        </a>
                      )}
                      {youtube && (
                        <a href={youtube} target="_blank" rel="noopener noreferrer">
                          <IconYoutube />
                        </a>
                      )}
                    </SupportSocials>
                  </Support>
                ))}
            </Supports>
          )}
        </div>
      </Grid>
    </>
  )
}

const EventModal = () => {
  const { db, getDoc, doc, collection, getDocs, query, where, limit } = firestore
  const { slug, previousId, previousSlug } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(undefined)
  const [organizers, setOrganizers] = useState(undefined)
  const [entered, setEntered] = useState(false)

  const getOrganizers = useCallback(async () => {
    getDocs(collection(db, 'organizers'))
      .then((querySnapshot) => {
        const res = []
        querySnapshot.forEach((doc) => {
          res.push({ name: doc.id, ...doc.data() })
        })
        return res.sort((a, b) => a.name.localeCompare(b.name)).map(({ name, website }) => ({ name, website }))
      })
      .then(setOrganizers)
  }, [collection, db, getDocs])

  useEffect(() => {
    getOrganizers()
  }, [getOrganizers])

  useEffect(() => {
    if (previousId && previousSlug) {
      const q = query(
        collection(db, 'events'),
        where('previousId', '==', previousId),
        where('previousSlug', '==', previousSlug),
        limit(1),
      )
      getDocs(q)
        .then((querySnapshot) => {
          let newUrl = ''
          querySnapshot.forEach((doc) => {
            newUrl = `/event/${doc.id}`
          })
          window.location.href = newUrl || '/news'
        })
        .catch(() => (window.location.href = '/news'))
      return
    }
    getDoc(doc(db, 'events', slug))
      .then((snapshot) => {
        setData(snapshot.data())
        setLoading(false)
        document.body.style.overflow = 'hidden'
      })
      .catch(() => history.push('/news'))
  }, [collection, db, doc, getDoc, getDocs, history, limit, previousId, previousSlug, query, slug, where])

  const organizerUrl = useMemo(
    () => data && organizers && organizers.find((o) => o.name === data.organizerName)?.website,
    [data, organizers],
  )

  return !data || !organizers ? null : (
    <Wrapper
      onClick={() => {
        if (entered) {
          return
        }
        document.body.style.overflow = 'auto'
        history.push('/news')
      }}
    >
      <Container
        className={loading ? undefined : 'show'}
        onMouseEnter={() => setEntered(true)}
        onMouseLeave={() => setEntered(false)}
      >
        <Event {...data} organizerUrl={organizerUrl ?? data.organizerUrl ?? ''} />
      </Container>
    </Wrapper>
  )
}

export default EventModal
