import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

import logoVolta from './Logo_Volta_23.jpg'
import logoVictoria from './Logo_Victoria23.jpg'

export const ClubGrid = styled.div`
  display: grid;
  width: 100%;
  max-width: 1000px;
  grid-gap: 15px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  //grid-template-rows: 130px 30px max-content max-content 30px;
  grid-template-rows: 130px 20px max-content 20px;

  @media (min-width: 640px) {
    grid-template-columns: 130px 130px 1fr 130px 130px;
    //grid-template-rows: 150px 30px max-content max-content 30px;
    grid-template-rows: 150px 30px max-content 30px;
  }

  @media (min-width: 1000px) {
    grid-template-columns: 100px 130px 1fr 100px 130px;
    grid-template-rows: max-content 40px; // max-content
  }
`

export const ClubAreaVolta = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${logoVolta}');
  background-size: contain;
  width: 100%;
  height: 100%;
  align-self: center;
  justify-self: center;
  text-align: center;
  grid-row: 1 / 2;
  grid-column: 1 / 3;

  @media (min-width: 640px) {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
  }

  @media (min-width: 1000px) {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
  }
`

export const TextArea = styled.div`
  font-size: 18px;
  line-height: 24px;
  align-self: center;
  justify-self: center;
  text-align: justify;
  grid-row: 3 / 4;
  grid-column: 1 / 5;
  font-size: 15px;

  @media (min-width: 640px) {
    font-size: 18px;
    grid-row: 3 / 4;
    grid-column: 1 / 6;
  }

  @media (min-width: 1000px) {
    grid-row: 1 / 2;
    grid-column: 3 / 4;
  }
`

/* export const SubTextArea = styled.div`
  font-size: 18px;
  line-height: 24px;
  align-self: center;
  justify-self: center;
  text-align: justify;
  grid-row: 4 / 5;
  grid-column: 1 / 5;
  font-size: 15px;

  @media (min-width: 640px) {
    font-size: 18px;
    grid-row: 4 / 5;
    grid-column: 1 / 6;
  }

  @media (min-width: 1000px) {
    grid-row: 3 / 4;
    grid-column: 1 / 6;
  }
`
 */
export const SubText = styled.div`
  font-size: 15px;
  line-height: 24px;
  align-self: center;
  justify-self: center;
  text-align: justify;
  margin-top: 30px;
  @media (min-width: 640px) {
    font-size: 18px;
  }
`

export const ClubAreaVictoria = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('${logoVictoria}');
  background-size: contain;
  width: 100%;
  height: 100%;

  align-self: center;
  justify-self: center;
  text-align: center;
  grid-row: 1 / 2;
  grid-column: 3 / 5;

  @media (min-width: 640px) {
    grid-row: 1 / 2;
    grid-column: 4 / 6;
  }

  @media (min-width: 1000px) {
    grid-row: 1 / 2;
    grid-column: 4 / 6;
  }
`

export const ClubName = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  display: block;

  @media (min-width: 640px) {
    font-size: 28px;
    line-height: 32px;
  }
`

export const ClubLogo = styled.div`
  display: block;
  margin: 15px 0;
`

export const CenterLinks = styled.div`
  justify-self: center;
  text-align: center;
  width: 240px;
  display: flex;
  justify-content: space-between;
  grid-row: 4 / 5;
  grid-column: 1 / 5;

  @media (min-width: 640px) {
    grid-row: 4 / 5;
    grid-column: 2 / 5;
  }

  @media (min-width: 1000px) {
    grid-row: 2 / 3;
    grid-column: 3 / 4;
  }
`

export const GalleryWrapper = styled.div`
  margin: 30px 0 0 0;
  border-radius: 15px;
  overflow: hidden;

  & > .image-gallery-bullets {
    z-index: 0;
  }
`

const Wrapper = styled.div`
  max-width: 1000px;
  margin: auto;
  text-align: center;

  & .image-gallery-description {
    width: 100%;
    font-size: 20px;
  }
`

export const Link = styled.a`
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: underline;
  justify-self: center;
  font-size: 14px;

  @media (min-width: 640px) {
    font-size: 18px;
    text-decoration: none;
  }

  & > svg {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    display: none;
    @media (min-width: 640px) {
      display: block;
    }
  }

  &.volta-tech {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    @media (min-width: 640px) {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }
    @media (min-width: 1000px) {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }
  }

  &.victoria-tech {
    grid-row: 2 / 3;
    grid-column: 4 / 5;
    @media (min-width: 640px) {
      grid-row: 2 / 3;
      grid-column: 5 / 6;
    }
    @media (min-width: 1000px) {
      grid-row: 2 / 3;
      grid-column: 5 / 6;
    }
  }
`

export const MailTo = styled(RouterLink)`
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: underline;
  justify-self: center;
  font-size: 14px;

  @media (min-width: 640px) {
    font-size: 18px;
    text-decoration: none;
  }

  & > svg {
    width: 22px;
    height: 22px;
    margin-right: 8px;
    display: none;
    @media (min-width: 640px) {
      display: block;
    }
  }

  &.volta-booking {
    grid-row: 2 / 3;
    grid-column: 1 / 2;

    @media (min-width: 640px) {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }
    @media (min-width: 1000px) {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }
  }

  &.victoria-booking {
    grid-row: 2 / 3;
    grid-column: 3 / 4;
    @media (min-width: 640px) {
      grid-row: 2 / 3;
      grid-column: 4 / 5;
    }
    @media (min-width: 1000px) {
      grid-row: 2 / 3;
      grid-column: 4 / 5;
    }
  }
`

export default Wrapper
