import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { FcGoogle as IconGoogle } from 'react-icons/fc'

import useMenu from '../../../../customHooks/useMenu'
import { auth } from '../../../../lib/firebase'
import Surface from '../../Surface'
import Structure from '../Structure'
import Styling from './Styling'

export default function GoogleLogin() {
  const { getAuth, signInWithPopup, GoogleAuthProvider } = auth
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const { setError } = useMenu()
  
  async function handleSubmit(e) {
    e.preventDefault()
    const auth = getAuth()
    const provider = new GoogleAuthProvider()

    setError('')
    setLoading(true)
    signInWithPopup(auth, provider)
      .then(() => {
        /*
        result
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log(user)
        */
        history.push('/admin')
      })
      .catch((error) => {
        /*
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = ;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        */
        setError(error.message)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Surface roles={[]}>
      <Structure>
        <Structure.ContentHeader>
          <Structure.SectionCenter>
            Bitte melde dich an
          </Structure.SectionCenter>
        </Structure.ContentHeader>
        <Structure.Content>
          <Styling>
            <Styling.Card>
              <Form onSubmit={handleSubmit}>
                <Button disabled={loading} className='w-100' type='submit' variant='light'>
                  <IconGoogle />&nbsp;Google Sign In
                </Button>
              </Form>
            </Styling.Card>
          </Styling>
        </Structure.Content>
      </Structure>
    </Surface>
  )
}
