import React from 'react'
import Styling from './Styling'

import Logo from './Logo'
import useMenu from '../../../../customHooks/useMenu'

import { useHistory, useLocation } from 'react-router-dom'
import useAuth from '../../../../customHooks/useAuth'

// https://react-icons.github.io/react-icons/
import {
  FcOrganization as IconDashboard,
  FcCalendar as IconEvents,
  FcGlobe as IconNews,
  FcKey as IconLogin,
  FcBusinessman as IconSignup,
  FcConferenceCall as IconUsers,
  FcMindMap as IconOrganizers,
  FcFaq as IconFaqs,
} from 'react-icons/fc'

import { FaPowerOff as IconLogout } from 'react-icons/fa'

export default function Navigation({ roles }) {
  const { pathname } = useLocation()
  const { menuOpen, setMenuOpen, setError } = useMenu()
  const { push } = useHistory()
  const { currentUser, logout } = useAuth()

  function handleClick(to) {
    push(to)
  }

  async function handleLogout() {
    setError('')

    try {
      await logout()
      push('/login')
    } catch {
      setError('Failed to log out')
    }
  }

  return (
    <Styling menuOpen={menuOpen} onClick={() => setMenuOpen(false)}>
      <Styling.Slider menuOpen={menuOpen}>
        <Styling.Brand>
          <Logo />
        </Styling.Brand>
        <Styling.Scrollable>
          {currentUser ? (
            <>
              {roles.includes('dashboard') && (
                <Styling.Button
                  type="button"
                  active={pathname === '/admin/dashboard'}
                  onClick={() => handleClick('/admin/dashboard')}
                >
                  <IconDashboard />
                  <div>Dashboard</div>
                </Styling.Button>
              )}
              {roles.includes('news') && (
                <Styling.Button
                  type="button"
                  active={pathname === '/admin/news'}
                  onClick={() => handleClick('/admin/news')}
                >
                  <IconNews />
                  <div>News</div>
                </Styling.Button>
              )}
              {roles.includes('events') && (
                <Styling.Button
                  type="button"
                  active={pathname === '/admin/events'}
                  onClick={() => handleClick('/admin/events')}
                >
                  <IconEvents />
                  <div>Events</div>
                </Styling.Button>
              )}
              {roles.includes('organizers') && (
                <Styling.Button
                  type="button"
                  active={pathname === '/admin/organizers'}
                  onClick={() => handleClick('/admin/organizers')}
                >
                  <IconOrganizers />
                  <div>Veranstalter</div>
                </Styling.Button>
              )}
              {roles.includes('faqs') && (
                <Styling.Button
                  type="button"
                  active={pathname === '/admin/faqs'}
                  onClick={() => handleClick('/admin/faqs')}
                >
                  <IconFaqs />
                  <div>FAQs</div>
                </Styling.Button>
              )}
              {roles.includes('users') && (
                <Styling.Button
                  type="button"
                  active={pathname === '/admin/users'}
                  onClick={() => handleClick('/admin/users')}
                >
                  <IconUsers />
                  <div>Benutzer</div>
                </Styling.Button>
              )}
              {/* roles.includes('settings') && (
                <Styling.Button
                  type="button"
                  active={pathname === '/admin/settings'}
                  onClick={() => handleClick('/admin/settings')}
                >
                  <IconSettings />
                  <div>Einstellungen</div>
                </Styling.Button>
              ) */}
              <Styling.Button type="button" onClick={handleLogout}>
                <IconLogout />
                <div>Logout</div>
              </Styling.Button>
            </>
          ) : (
            <>
              <Styling.Button type="button" active={pathname === '/login'} onClick={() => handleClick('/login')}>
                <IconLogin />
                <div>Anmeldung</div>
              </Styling.Button>
              {false && (
                <Styling.Button type="button" active={pathname === '/signup'} onClick={() => handleClick('/signup')}>
                  <IconSignup />
                  <div>Sign Up</div>
                </Styling.Button>
              )}
            </>
          )}
        </Styling.Scrollable>
      </Styling.Slider>
    </Styling>
  )
}
