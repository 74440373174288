import React, { useState, useEffect, useCallback } from 'react'
import ReactQuill from 'react-quill'

import { firestore } from '../../../../../lib/firebase'
import useAuth from '../../../../../customHooks/useAuth'

function Notes() {
  const { currentUser } = useAuth()
  const { email } = currentUser
  const { db, collection, where, query, orderBy, onSnapshot, doc, setDoc } = firestore

  const [note, setNote] = useState('')
  const [id, setId] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const q = query(collection(db, 'users'), where('email', '==', email))
    onSnapshot(q, (snapshot) => {
      if (loading) {
        setId(snapshot.docs[0].id)
        setNote(snapshot.docs[0].data()?.note || '')
        setLoading(false)
      }
    })
  }, [collection, db, email, loading, onSnapshot, orderBy, query, where])

  const save = useCallback(() => {
    console.log('save')
    setLoading(true)
    setDoc(doc(db, 'users', id), { note }, { merge: true })
    window.setTimeout(() => setLoading(false), 300)
  }, [db, doc, id, note, setDoc])

  return (
    <div className="my-2">
      <h4 className="mx-2" style={{ padding: 0 }}>
        Deine Notizen
      </h4>
      <div className="mx-2">
        <ReactQuill
          theme="snow"
          value={note}
          onChange={setNote}
          onBlur={save}
          style={{
            height: 'calc(100vh - 305px)',
          }}
        />
      </div>
    </div>
  )
}

export default Notes
