import React, { useContext, useState, useEffect } from "react"
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword as upx,
} from "../lib/firebase"

export const AuthContext = React.createContext()

export function AuthProvider({ children }) {
  const auth = getAuth()
  const [currentUser, setCurrentUser] = useState()
  const [loading, setLoading] = useState(true)

  function signup(email, password) {
    // console.log('AuthProvider', 'signup')
    return createUserWithEmailAndPassword(auth, email, password)
  }

  function login(email, password) {
    // console.log('AuthProvider', 'login')
    return signInWithEmailAndPassword(auth, email, password)
  }

  function logout() {
    // console.log('AuthProvider', 'logout')
    return signOut(auth)
  }

  function resetPassword(email) {
    // console.log('AuthProvider', 'resetPassword')
    return sendPasswordResetEmail(auth, email)
  }

  function updateEmail(email) {
    // console.log('AuthProvider', 'updateEmail')
    return currentUser.updateEmail(email)
  }

  function updatePassword(password) {
    // console.log('AuthProvider', 'updatePassword')
    return upx(currentUser, password)
  }

  useEffect(() => {
    // console.log('called once ')
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [auth])

  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

export default function useAuth() {
  return useContext(AuthContext)
}
