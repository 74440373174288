import React from 'react'
import styled from 'styled-components'

import Logo from '../Logo'

const Area = styled.div`
  display: flex;
  align-items: end;
  color: inherit;
`

const ClubName = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-left: 2px;
  margin-bottom: -3px;
  white-space: nowrap;
  color: inherit;

  @media (min-width: 640px) {
    font-size: 16px;
  }

`

const Club = ({ volta, garden, victoria }) => {
  let location
  switch (true) {
    case volta && garden && !victoria:
    case volta && !garden && !victoria:
      location = 'Club Volta'
      break
    case !volta && garden && !victoria:
      location = 'Biergarten'
      break
    case !volta && garden && victoria:
    case !volta && !garden && victoria:
      location = 'Carlswerk Victoria'
      break
    case volta && !garden && victoria:
      location = 'Volta & Victoria'
      break
    default:
      location = 'Gesamter Club'
  }

  return (
    <Area>
      <Logo volta={volta} garden={garden} victoria={victoria} width={30} height={25} />
      <ClubName>.{location}</ClubName>
    </Area>
  )
}

export default Club
