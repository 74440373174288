import styled from '@emotion/styled'
import { mq } from '../../../lib/theme'

const Styling = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  top: ${({ theme }) => theme.dimensions.headerHeight.mobile};
  height: ${({ theme }) => `calc(100% - ${theme.dimensions.headerHeight.mobile})`};
  background: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => theme.dimensions.distance.mobile};

  ${mq(breakpoints => breakpoints.tablet)} {
    top: ${({ theme }) => theme.dimensions.headerHeight.tablet};
    height: ${({ theme }) => `calc(100% - ${theme.dimensions.headerHeight.tablet})`};
    left: ${({ theme }) => theme.dimensions.menuWidth.tablet};
    width: ${({ theme }) => `calc(100% - ${theme.dimensions.menuWidth.tablet})`};
  }

  ${mq(breakpoints => breakpoints.desktop)} {
    top: ${({ theme }) => theme.dimensions.headerHeight.desktop};
    height: ${({ theme }) => `calc(100% - ${theme.dimensions.headerHeight.desktop})`};
    left: ${({ theme }) => theme.dimensions.menuWidth.desktop};
    width: ${({ theme }) => `calc(100% - ${theme.dimensions.menuWidth.desktop})`};
    padding: ${({ theme }) => theme.dimensions.distance.desktop};
  }
`

Styling.ContentHeader = styled.div`
  height: ${({ theme }) => `${theme.dimensions.menuButtonHeight.mobile}`};
  display: grid;
  align-items: center;
  grid-template-columns: 296px 1fr;
  grid-gap: ${({ theme }) => theme.dimensions.distance.mobile};

  ${mq(breakpoints => breakpoints.desktop)} {
    grid-gap: ${({ theme }) => theme.dimensions.distance.desktop};
  }
  ${mq(breakpoints => breakpoints.desktop)} {
    height: ${({ theme }) => `calc(${theme.dimensions.menuButtonHeight.desktop} - ${theme.dimensions.distance.desktop})`};
  }
`

Styling.Section = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.name.mobile};

  ${mq(breakpoints => breakpoints.tablet)} {
    font-size: ${({ theme }) => theme.font.size.name.tablet};
    margin-top: -5px;
  }

  ${mq(breakpoints => breakpoints.desktop)} {
    margin-top: 0;
  }
`

Styling.SectionCenter = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.name.mobile};
  grid-column: 1 / 3;
  text-align: center;

  ${mq(breakpoints => breakpoints.tablet)} {
    font-size: ${({ theme }) => theme.font.size.name.tablet};
    margin-top: -5px;
  }

  ${mq(breakpoints => breakpoints.desktop)} {
    margin-top: 0;
  }
`

Styling.Interaction = styled.div`
  ${mq(breakpoints => breakpoints.tablet)} {
    margin-top: -5px;
  }

  ${mq(breakpoints => breakpoints.desktop)} {
    margin-top: 0;
  }

`

Styling.Content = styled.div`
  overflow: auto;
  height: ${({ theme }) => `calc(100% - ${theme.dimensions.distance.mobile} - ${theme.dimensions.distance.mobile})`};

  ${mq(breakpoints => breakpoints.desktop)} {
    height: ${({ theme }) => `calc(100% - ${theme.dimensions.distance.desktop} - ${theme.dimensions.distance.desktop})`};
  }
`

export default Styling
