import React from 'react'

import Logo from '../../shared/Logo'
import Wrapper, { ClubArea, ClubName, Anfahrt, MapVolta, MapVictoria } from './Styling'

const AnfahrtSection = () => {
  return (
    <Wrapper>
      <Anfahrt>
        <p>Wir empfehlen die Anreise mit dem öffentlichen Nahverkehr!</p>
        <p>
          <strong>KVB Haltestellen:</strong>
          <br />
          Keupstraße: Linie 4, ca. 5 min. zu Fuß
          <br />
          Wiener Platz: Linie 4 / 13 / 18, ca. 15 min. zu Fuß
          <br />
          Schanzenstraße/Schauspielhaus: Bus 150, ca. 3 min. zu Fuß
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Parkmöglichkeiten:</strong>
          <br />
          Direkt neben unseren Veranstaltungshallen befindet sich ein Parkhaus mit 440 Stellplätzen. Dort könnt ihr von
          18:00 Uhr bis 08:00 Uhr zum Veranstaltungstarif für 5,00€ parken, außerhalb dieser Zeiten zahlt ihr 2,00€/Std.
        </p>
      </Anfahrt>
      <MapVolta>
        <ClubArea>
          <Logo volta width={30} height={25} />
          <ClubName>Club Volta</ClubName>
        </ClubArea>
        <iframe
          async
          title="Club Volta"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10051.000863758623!2d7.0125218!3d50.9653223!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7252bded0188683d!2sClub%20Volta!5e0!3m2!1sde!2sde!4v1643536198161!5m2!1sde!2sde"
          width="100%"
          height="500"
          loading="lazy"
          allowFullScreen
        />
      </MapVolta>
      <MapVictoria>
        <ClubArea>
          <Logo victoria width={30} height={25} />
          <ClubName>Carlswerk Victoria</ClubName>
        </ClubArea>
        <iframe
          async
          title="Carlswerk Victoria"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10051.055083867426!2d7.0121199!3d50.9650717!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x830116c63c1fe94d!2sCarlswerk%20Victoria!5e0!3m2!1sde!2sde!4v1643484319863!5m2!1sde!2sde"
          width="100%"
          height="500"
          loading="lazy"
          allowFullScreen
        />
      </MapVictoria>
    </Wrapper>
  )
}

export default AnfahrtSection
