import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { Container, Row, Col, Form, Button, ListGroup } from 'react-bootstrap'

/* import { List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"; */

import { MdCheck as IconCheck, MdDelete as IconDelete } from 'react-icons/md'

import { firestore } from '../../../../../lib/firebase'
import useAuth from '../../../../../customHooks/useAuth'

function Todos() {
  const { currentUser } = useAuth()
  const { email } = currentUser
  const { db, collection, where, query, orderBy, onSnapshot, addDoc, serverTimestamp, doc, deleteDoc, setDoc } =
    firestore

  const [todo, setTodo] = useState('')
  const [todos, setTodos] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const q = query(collection(db, 'todos'), where('email', '==', email), orderBy('timestamp', 'desc'))
    onSnapshot(q, (snapshot) => {
      if (loading) {
        setTodos(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            item: doc.data(),
          })),
        )
        setLoading(false)
      }
    })
  }, [collection, db, email, loading, onSnapshot, orderBy, query, where])

  const compl = useCallback(
    (id) => setDoc(doc(db, 'todos', id), { completed: new Date().toISOString() }, { merge: true }),
    [db, doc, setDoc],
  )

  const del = useCallback((id) => deleteDoc(doc(db, 'todos', id)), [db, deleteDoc, doc])

  const addTodo = useCallback(
    (e) => {
      e.preventDefault()
      addDoc(collection(db, 'todos'), {
        todo,
        email,
        completed: '',
        created: new Date().toISOString(),
        timestamp: serverTimestamp(),
      })
      setTodo('')
    },
    [addDoc, collection, db, email, serverTimestamp, todo],
  )

  const localeDateTime = useCallback((d) => `${new Date(d).toLocaleString('de-DE')} Uhr`, [])
  const open = useMemo(() => todos.filter((t) => !t.item.completed), [todos])
  const done = useMemo(
    () => todos.filter((t) => t.item.completed).sort((a, b) => b.item.completed.localeCompare(a.item.completed)),
    [todos],
  )

  return (
    <div className="my-2">
      <h4 className="mx-2" style={{ padding: 0 }}>
        Deine ToDos ({open.length})
      </h4>
      <form onSubmit={addTodo} className="mb-3">
        <Container>
          <Row>
            <Col xs={9}>
              <Form.Control type="text" value={todo} onChange={(e) => setTodo(e.target.value)} maxLength={250} />
            </Col>
            <Col xs={3}>
              <Button className="btn-primary w-100" type="submit" disabled={!todo}>
                Neues Todo
              </Button>
            </Col>
          </Row>
        </Container>
      </form>

      {open.length === 0 ? (
        <h5 className="mx-2" style={{ padding: 0 }}>
          👍🏻 Keine offenen ToDos
        </h5>
      ) : (
        <>
          <h5 className="mx-2" style={{ padding: 0 }}>
            Unerledigt
          </h5>
          <div style={{ margin: '12px', height: 'calc(100vh - 582px)', overflow: 'auto' }}>
            <ListGroup>
              {open.map(({ item, id }) => (
                <ListGroup.Item key={id} as="li" className="d-flex justify-content-between align-items-start">
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">{localeDateTime(item.created)}</div>
                    {item.todo}
                  </div>
                  <div className="p-1" style={{ whiteSpace: 'nowrap' }}>
                    <Button variant="success" onClick={() => compl(id)} className="mx-2">
                      <IconCheck />
                    </Button>
                    <Button variant="danger" onClick={() => del(id)}>
                      <IconDelete />
                    </Button>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </>
      )}

      {done.length > 0 && (
        <div style={{ marginTop: '30px' }}>
          <h5 className="mx-2" style={{ padding: 0 }}>
            Erledigt
          </h5>
          <div style={{ margin: '12px', height: '165px', overflow: 'auto' }}>
            <ListGroup>
              {done.map(({ item, id }) => (
                <ListGroup.Item key={id} as="li" className="d-flex justify-content-between align-items-start">
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">{localeDateTime(item.completed)}</div>
                    {item.todo}
                  </div>
                  <div className="p-1" style={{ whiteSpace: 'nowrap' }}>
                    <Button variant="danger" onClick={() => del(id)}>
                      <IconDelete />
                    </Button>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </div>
      )}
    </div>
  )
}

export default Todos
