/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import 'react-quill/dist/quill.snow.css'
import { Form, Button, ButtonGroup, Row, Col, InputGroup } from 'react-bootstrap'

import { MdDelete as IconDelete } from 'react-icons/md'

import {
  IoHome as IconWebsite,
  IoPerson as IconOrganizer,
  IoLogoYoutube as IconYoutube,
  IoLogoFacebook as IconFacebook,
  IoLogoTiktok as IconTiktok,
  IoRocket as IconMainAct,
  IoCaretUp as UpIcon,
  IoCaretDown as DownIcon,
} from 'react-icons/io5'

import { AiFillInstagram as IconInstagram } from 'react-icons/ai'

import { BsImage as IconImage } from 'react-icons/bs'

import Styling from './Styling'

export default function Act({ act, upload, delImage, deleteAct, editAct, actindex, moveAct, acts }) {
  const { name, website, tiktok, facebook, instagram, youtube, type } = act

  return (
    <>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Row>
            <Col>
              <ButtonGroup className="my-2 w-100">
                <Button variant="success" onClick={() => moveAct(actindex, 'up')} disabled={actindex === 0}>
                  <UpIcon />
                </Button>
                <Button variant="primary" onClick={() => editAct(actindex)}>
                  ändern
                </Button>
                <Button variant="danger" onClick={() => deleteAct(actindex)}>
                  löschen
                </Button>
                <Button
                  variant="success"
                  onClick={() => moveAct(actindex, 'down')}
                  disabled={actindex === acts.length - 1}
                >
                  <DownIcon />
                </Button>
              </ButtonGroup>
              <InputGroup className="my-2">
                <InputGroup.Text style={{ width: '150px' }}>
                  <IconMainAct />
                  &nbsp;{actindex + 1}. Type
                </InputGroup.Text>
                <Form.Select value={type} disabled>
                  <option value="main">Main Act</option>
                  <option value="special">Special Guest</option>
                  <option value="support">Support Act</option>
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup className="my-2">
                <InputGroup.Text style={{ width: '150px' }}>
                  <IconOrganizer />
                  &nbsp;Name
                </InputGroup.Text>
                <Form.Control value={name} disabled />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup className="my-2">
                <InputGroup.Text style={{ width: '150px' }}>
                  <IconWebsite />
                  &nbsp;Website
                </InputGroup.Text>
                <Form.Control placeholder="https://" value={website} disabled />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup className="my-2">
                <InputGroup.Text style={{ width: '150px' }}>
                  <IconFacebook />
                  &nbsp;facebook
                </InputGroup.Text>
                <Form.Control placeholder="https://" value={facebook} disabled />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup className="my-2">
                <InputGroup.Text style={{ width: '150px' }}>
                  <IconInstagram />
                  &nbsp;Instagram
                </InputGroup.Text>
                <Form.Control placeholder="https://" value={instagram} disabled />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup className="my-2">
                <InputGroup.Text style={{ width: '150px' }}>
                  <IconTiktok />
                  &nbsp;Tiktok
                </InputGroup.Text>
                <Form.Control placeholder="https://" value={tiktok} disabled />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup className="my-2">
                <InputGroup.Text style={{ width: '150px' }}>
                  <IconYoutube />
                  &nbsp;Youtube
                </InputGroup.Text>
                <Form.Control placeholder="https://" value={youtube} disabled />
              </InputGroup>
            </Col>
          </Row>
        </Col>
        <Col xs={6}>
          {(act?.images || []).length === 0 ? (
            <>
              <Row>
                <Col>
                  <InputGroup className="my-2">
                    <InputGroup.Text style={{ width: '80px' }}>
                      <IconImage />
                      &nbsp;Bild
                    </InputGroup.Text>
                    <Form.Control type="file" onChange={(e) => upload(e, actindex)} />
                  </InputGroup>
                </Col>
              </Row>
            </>
          ) : (
            <Styling.Images>
              {act.images.map(({ src }) => (
                <Styling.Image key={src} src={src}>
                  <div style={{ textAlign: 'right' }}>
                    <Button size="sm" variant="danger" onClick={() => delImage(0, actindex)}>
                      <IconDelete />
                    </Button>
                  </div>
                </Styling.Image>
              ))}
            </Styling.Images>
          )}
        </Col>
      </Row>
    </>
  )
}
