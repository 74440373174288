import styled from '@emotion/styled'
// import { mq } from '../../../../lib/theme'

const Styling = styled.div`
  height: 100%;
  max-width: 400px;
  width: 100%;
  margin: auto;
`

Styling.Card = styled.div`
  background: ${({ theme }) => theme.colors.foreground};
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.colors.shadowCard};
  padding: ${({ theme }) => theme.dimensions.distance.mobile};
`

export default Styling
