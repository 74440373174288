import React, { Fragment, useMemo } from 'react'

import { FcCancel as IconOffline } from 'react-icons/fc'

import Logo from '../../../shared/Logo'
import Styling from './Styling'

function NewsList({ news, newsId, setNewsId }) {
  const [yesterday, tomorrow] = useMemo(() => {
    const today = new Date(new Date().toISOString().slice(0, 10))
    return [
      new Date(new Date(today.setDate(today.getDate() - 1)).toISOString().slice(0, 10)),
      new Date(new Date(today.setDate(today.getDate() + 2)).toISOString().slice(0, 10)),
    ]
  }, [])

  const [displays, futures, pasts, offlines] = useMemo(() => {
    return [
      news
        .filter((r) => {
          if (!r.online) {
            return false
          }
          if (!r?.dateEnd) {
            return new Date(r.date) < tomorrow
          }
          return new Date(r.date) < tomorrow && new Date(r?.dateEnd) > yesterday
        })
        .sort((a, b) => b.lastUpdate.localeCompare(a.lastUpdate)),
      news.filter((r) => {
        if (!r.online) {
          return false
        }
        return new Date(r.date) > tomorrow // noch nicht aktiv
      }),
      news.filter((r) => {
        if (!r.online || !r?.dateEnd) {
          return false
        }
        return new Date(r?.dateEnd) < yesterday // schon vergangen
      }),
      news.filter((r) => !r.online),
    ]
  }, [news, tomorrow, yesterday])

  return (
    <Styling.Card>
      {news.length === 0 ? (
        <Styling.NewsList>
          <Styling.NewsListEntry disabled>
            <em>Keine geplanten News</em>
          </Styling.NewsListEntry>
        </Styling.NewsList>
      ) : (
        <>
          {displays.length > 0 && (
            <>
              <Styling.Label>
                Derzeit auf der Startseite
                {displays.length > 3 && (
                  <>
                    <br />
                    (nur die ersten drei)
                  </>
                )}
              </Styling.Label>
              <Styling.NewsList>
                {displays.map((n) => (
                  <Styling.NewsListEntry key={n.id} active={newsId === n.id} onClick={() => setNewsId(n.id)}>
                    {n.online ? (
                      <Logo volta={n.volta} garden={n.garden} victoria={n.victoria} width={27} height={22} />
                    ) : (
                      <IconOffline />
                    )}
                    {n.label || <em>Leeres News</em>}
                  </Styling.NewsListEntry>
                ))}
              </Styling.NewsList>
            </>
          )}

          {futures.length > 0 && (
            <>
              <Styling.Label>Zukünftig</Styling.Label>
              <Styling.NewsList>
                {futures.map((n) => (
                  <Styling.NewsListEntry key={n.id} active={newsId === n.id} onClick={() => setNewsId(n.id)}>
                    {n.online ? (
                      <Logo volta={n.volta} garden={n.garden} victoria={n.victoria} width={27} height={22} />
                    ) : (
                      <IconOffline />
                    )}
                    {n.label || <em>Leeres News</em>}
                  </Styling.NewsListEntry>
                ))}
              </Styling.NewsList>
            </>
          )}

          {pasts.length > 0 && (
            <>
              <Styling.Label>Vergangen</Styling.Label>
              <Styling.NewsList>
                {pasts.map((n) => (
                  <Styling.NewsListEntry key={n.id} active={newsId === n.id} onClick={() => setNewsId(n.id)}>
                    {n.online ? (
                      <Logo volta={n.volta} garden={n.garden} victoria={n.victoria} width={27} height={22} />
                    ) : (
                      <IconOffline />
                    )}
                    {n.label || <em>Leeres News</em>}
                  </Styling.NewsListEntry>
                ))}
              </Styling.NewsList>
            </>
          )}

          {offlines.length > 0 && (
            <>
              <Styling.Label>nicht online</Styling.Label>
              <Styling.NewsList>
                {offlines.map((n) => (
                  <Styling.NewsListEntry key={n.id} active={newsId === n.id} onClick={() => setNewsId(n.id)}>
                    {n.online ? (
                      <Logo volta={n.volta} garden={n.garden} victoria={n.victoria} width={27} height={22} />
                    ) : (
                      <IconOffline />
                    )}
                    {n.label || <em>Leeres News</em>}
                  </Styling.NewsListEntry>
                ))}
              </Styling.NewsList>
            </>
          )}
        </>
      )}
    </Styling.Card>
  )
}

export default NewsList
