const theme = (colorScheme) => ({
  colors: colorScheme === 'light'
    ? {
      default: '#231F20',
      background: '#F1F2F7',
      foreground: '#FFFFFF',
      iconActive: '#be1e2e',
      iconNorm: '#231F2070',
      itemActive: '#1565C0',
      itemHover: '#86C2F4',
      menuActive: '#F1F2F7',
      menuHover: '#F1F2F7',
      menuNorm: '#FEFEFE',
      buethRed: '#be1e2e',
      buethDark: '#A6A5A5',
      shadowCard: '0 2px 18px 1px rgba(49,53,72,.1)',
      shadowNav: '0 2px 18px 1px rgba(49,53,72,.1)',
      gradientBueth: 'linear-gradient(180deg, #be1e2e 0%, #231F20 70%)',
      gradientLoading: 'linear-gradient(180deg, #be1e2e 0%, #231F20 70%)',
    }
    : {
      default: '#231F20',
      background: '#F1F2F7',
      foreground: '#FFFFFF',
      iconActive: '#be1e2e',
      iconNorm: '#231F2070',
      itemActive: '#1565C0',
      itemHover: '#86C2F4',
      menuActive: '#F1F2F7',
      menuHover: '#F1F2F7',
      menuNorm: '#FEFEFE',
      buethRed: '#be1e2e',
      buethDark: '#A6A5A5',
      shadowCard: '0 2px 18px 1px rgba(49,53,72,.1)',
      shadowNav: '0 2px 18px 1px rgba(49,53,72,.1)',
      gradientBueth: 'linear-gradient(180deg, #be1e2e 0%, #231F20 70%)',
      gradientLoading: 'linear-gradient(90deg, #F1F2F7 15%, #be1e2e 50%, #F1F2F7 85%)',
    },
  dimensions: {
    distance: {
      mobile: '12px',
      tablet: '27px',
      desktop: '27px',
    },
    headerHeight: {
      mobile: '60px',
      tablet: '72px',
      desktop: '72px',
    },
    menuWidth: {
      mobile: '180px',
      tablet: '180px',
      desktop: '150px',
    },
    menuButtonHeight: {
      mobile: '44px',
      desktop: '80px',
    },
    menuIcon: {
      mobile: '20px',
    }
  },
  breakpoints: {
    min: '320px',
    tablet: '800px',
    desktop: '1200px',
    max: '1440px',
  },
  transition: {
    standard: '0.3s ease-in-out',
  },
  font: {
    weight: {
      light: 200,
      normal: 400,
      semibold: 600,
      bold: 700,
    },
    size: {
      menu: {
        mobile: '16px',
        tablet: '18px',
      },
      name: {
        mobile: '16px',
        tablet: '20px',
      },
    },
  },
})

export const mediaPrefix = (type = 'all') => (type ? `@media ${type} and ` : '@media ')

export const mq = callback => `${mediaPrefix()}(min-width: ${callback(theme().breakpoints)})`

export default theme
