import styled from '@emotion/styled'
import { mq } from '../../../../lib/theme'
import logo from './carlswerk-logo.png'

const Styling =  styled.header`
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  position: absolute;
  overflow: hidden;
  background: white;
  align-items: center;
  grid-template-columns: ${({ theme }) => `${theme.dimensions.distance.mobile} ${theme.dimensions.menuIcon.mobile} ${theme.dimensions.distance.mobile} 109px 1fr ${theme.dimensions.distance.mobile}`};
  height: ${({ theme }) => theme.dimensions.headerHeight.mobile};
  min-width: ${({ theme }) => theme.breakpoints.min};
  box-shadow: ${({ theme }) => theme.colors.shadowNav};

  ${mq(breakpoints => breakpoints.tablet)} {
    height: ${({ theme }) => theme.dimensions.headerHeight.tablet};
    grid-template-columns: ${({ theme }) => `${theme.dimensions.distance.tablet} 153px 1fr ${theme.dimensions.distance.tablet} 45px ${theme.dimensions.distance.tablet}`};
  }
`

Styling.MenuToggle = styled.button`
  display: inline-block;
  cursor: pointer;
  border: 0;
  padding: 0;
  background: transparent;
  outline: none !important;
  grid-column: 2 / 3;

  & > div {
    height: 2px;
    margin: 6px 0;
    transition: ${({ theme }) => theme.transition.standard};
    width: ${({ theme }) => theme.dimensions.menuIcon.mobile};
    background-color: ${({ theme, menuOpen }) => theme.colors[menuOpen ? 'buethRed' : 'buethDark']};
  }

  ${({ menuOpen }) => (menuOpen ? `
    & > div {
      &:nth-of-type(1) {
        transform: rotate(-45deg) translate(-6px, 5px) ;
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: rotate(45deg) translate(-6px, -5px) ;
      }
    }` : null)}

  ${mq(breakpoints => breakpoints.tablet)} {
    display: none;
  }  
`

Styling.Logo = styled.div`
  background-image: ${`url("${logo}")`};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  height: 40px;
  width: 100px;
  grid-column: 4 / 5;

  ${mq(breakpoints => breakpoints.tablet)} {
    height: 47px;
    width: 93px;
    grid-column: 2 / 3;
  }
`

Styling.Name = styled.div`
  line-height: 1;
  text-align: right;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.name.mobile};
  grid-column: ${({ photoURL }) => photoURL ? '5 / 6' : '5 / 8'};

  ${mq(breakpoints => breakpoints.tablet)} {
    font-size: ${({ theme }) => theme.font.size.name.tablet};
    grid-column: ${({ photoURL }) => {
      // console.log({ photoURL })
      return photoURL ? '3 / 4' : '3 / 6'
    }};
  }
`

Styling.Image = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  box-shadow: ${({ theme }) => theme.colors.shadowNav};
  border: ${({ theme }) => `2px solid ${theme.colors.menuActive}`};
  background-color: ${({ theme }) => theme.colors.background};
  display: none;
  grid-column: 5 / 6;

  ${mq(breakpoints => breakpoints.tablet)} {
    display: block;
  }

  background-image: ${({ photoURL }) => `url("${photoURL}")`};
`

Styling.Error = styled.div`
  position: absolute;
  top: 60px;
  left: 12px;
  right: 12px;
  background: gold;
  padding: 15px 15px;
  color: #C60D1B;
  background: #FFEDEB;
  border-left: 1px solid #C60D1B;
  border-bottom: 1px solid #C60D1B;
  border-right: 1px solid #C60D1B;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: height 0.3s linear;

  & > button {
    background: transparent;
    border: 0;
    color: inherit;
  }

  ${mq(breakpoints => breakpoints.tablet)} {
    top: 72px;
    left: 177px;
    right: 26px;
  }

`

export default Styling
