import styled from 'styled-components'

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 80px;
  bottom: 0;
  padding: 0 15px 15px 15px;

  @media (min-width: 640px) {
    padding: 0 30px 30px 30px;
  }
`

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 1000px;
  margin: auto;
  height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  /* border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px; */
  padding: 0;
  transition: 0.3s height ease-in-out;

  &.show {
    height: 100%;
    overflow-y: auto;
  }
`

export const Image = styled.div`
  background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0.5) 80%);

  & > div {
    background-image: ${({ src }) => `url(${src})`};
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
  }
  width: 100%;
  height: calc(50vh - 40px);
  max-height: 450px;
  border-bottom: 1px solid #ccc;
`

export const Grid = styled.div`
  margin: 30px;
  min-height: calc(100vh - 560px);

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 170px;
    grid-gap: 45px;
    row-gap: 5px;
  }

  @media (min-width: 1000px) {
    margin: 30px 60px;
    grid-gap: 60px;
    row-gap: 5px;
  }
`

export const TourName = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
`

export const EventName = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 34px;
  margin-bottom: 8px;
`

export const SupportArtist = styled.div`
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: 700;
`

export const Description = styled.div`
  font-size: 14px;
`

export const Supports = styled.div``

export const Support = styled.div`
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-template-rows: . . 1fr 1fr .;
  grid-gap: 30px;
  row-gap: 5px;
  margin-top: 30px;
`

export const SupportImage = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 6;

  & > div {
    width: 130px;
    height: 130px;
    display: block;
    background-size: cover;
    background-color: black;
    background-repeat: no-repeat;
    background-position: center center;
    font-size: initial;
    margin-bottom: calc(var(--m) - var(--s) * 0.5);
    clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
  }
`

export const SupportName = styled.div`
  grid-column: 2 / 3;
  grid-row: 3 / 4;
  align-self: center;
  font-size: 18px;
  font-weight: 600;
`

export const SupportSocials = styled.div`
  grid-column: 2 / 3;
  grid-row: 4 / 5;
  align-self: center;

  & > a {
    color: black;
    text-decoration: none;
    & > svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
`

export const ExtraInfo = styled.div`
  margin-bottom: 8px;
`

export const DateClub = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;

  @media (min-width: 768px) {
    display: block;
  }
`

export const EventDate = styled.div`
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 4px;
`

export const ClubArea = styled.div`
  margin-bottom: 8px;
`

export const TimeAdmission = styled.div`
  margin-bottom: 8px;
  & > strong {
    width: 120px;
    display: inline-block;
  }
  & > div {
    display: inline;
  }
  @media (min-width: 768px) {
    & > div {
      display: block;
    }
  }
`

export const TimeBegin = styled.div`
  margin-bottom: 8px;
  & > strong {
    width: 120px;
    display: inline-block;
  }
  & > div {
    display: inline;
  }
  @media (min-width: 768px) {
    & > div {
      display: block;
    }
  }
`

export const PresalePrice = styled.div`
  margin-bottom: 8px;
  & > strong {
    width: 120px;
    display: inline-block;
  }
  & > div {
    display: inline;
  }
  @media (min-width: 768px) {
    & > div {
      display: block;
    }
  }
`

export const BoxOfficePrice = styled.div`
  margin-bottom: 8px;
  & > strong {
    width: 120px;
    display: inline-block;
  }
  & > div {
    display: inline;
  }
  @media (min-width: 768px) {
    & > div {
      display: block;
    }
  }
`

export const Organizer = styled.div`
  margin-bottom: 8px;
  & > strong {
    width: 120px;
    display: inline-block;
  }
  & > div {
    display: inline;
    & > a {
      color: black;
    }
  }
  @media (min-width: 768px) {
    & > div {
      display: block;
    }
  }
`

export const FacebookAttend = styled.div`
  justify-self: center;
  padding: 30px 0;

  & > a {
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
    & > svg {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }
  }
`

export const TicketsLink = styled.div`
  justify-self: center;
  padding: 8px 0 16px;

  & > a {
    padding: 3px 8px;
    display: flex;
    align-items: center;
    color: white;
    background: black;
    font-size: 26px;
    border-radius: 5px;
    text-decoration: none;
    & > svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
`

export default Wrapper
