import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import { AuthProvider } from '../customHooks/useAuth'
import { MenuProvider } from '../customHooks/useMenu'

import useTheme from '../lib/theme'
import useColorScheme from '../customHooks/useColorScheme'

import CarlswerkVictoria from './CarlswerkVictoria'

import Admin from './Admin'
import Login from './Admin/Content/Login'
// import Signup from "./Admin/Content/Signup"
import PrivateRoute from './Admin/PrivateRoute'

function App() {
  const colorScheme = useColorScheme()
  const theme = useTheme(colorScheme)

  return (
    <MenuProvider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              {/* Open */}
              <Route exact path="/">
                <Redirect to="/news" />
              </Route>
              <Route exact path="/news" component={CarlswerkVictoria} />
              <Route path="/news/:slug" component={CarlswerkVictoria} />
              <Route exact path="/event/:previousId/:previousSlug" component={CarlswerkVictoria} />
              <Route path="/event/:slug" component={CarlswerkVictoria} />

              {/* Auth */}
              <Route path="/login" component={Login} />
              {/* <Route path="/signup" component={Signup} /> */}
              {/* <Route path="/admin/forgot-password" component={ForgotPassword} /> */}

              {/* Admin */}
              <PrivateRoute exact path="/admin" />
              <PrivateRoute path="/admin/" component={Admin} />

              <Route exact path="/programm/:previousId/:previousSlug" component={CarlswerkVictoria} />
              <Route path="/:scrollTo" component={CarlswerkVictoria} />
            </Switch>
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </MenuProvider>
  )
}

export default App
