import styled from '@emotion/styled'
import { mq } from '../../../../lib/theme'

const Styling = styled.div`
  height: 100%;
  display: grid;
  grid-gap: ${({ theme }) => theme.dimensions.distance.mobile};
  grid-template-columns: repeat(auto-fill, minmax(296px, 1fr));

  ${mq((breakpoints) => breakpoints.tablet)} {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }

  ${mq((breakpoints) => breakpoints.desktop)} {
    grid-gap: ${({ theme }) => theme.dimensions.distance.desktop};
    grid-template-columns: repeat(auto-fill, minmax(800px, 1fr));
  }
`

Styling.Card = styled.div`
  background: ${({ theme }) => theme.colors.foreground};
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.colors.shadowCard};
  padding: ${({ theme }) => theme.dimensions.distance.mobile};
`

export default Styling
