import styled from '@emotion/styled'
import { mq } from '../../../../lib/theme'

const Styling = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: ${({ menuOpen }) => (menuOpen ? 'rgba(1, 1, 1, 0.7)' : 'rgba(1, 1, 1, 0)')};
  width: ${({ menuOpen }) => (menuOpen ? '100%' : '0')};
  transition: background ${({ theme }) => theme.transition.standard};

  ${mq(breakpoints => breakpoints.tablet)} {
    width: ${({ theme }) => theme.dimensions.menuWidth.tablet};
  }

  ${mq(breakpoints => breakpoints.desktop)} {
    width: ${({ theme }) => theme.dimensions.menuWidth.desktop};
  }
`

Styling.Slider = styled.nav`
  top: 0;
  height: 100%;
  position: fixed;
  background: ${({ theme }) => theme.colors.menuNorm};
  left: ${({ theme, menuOpen }) => (menuOpen ? '0' : `-${theme.dimensions.menuWidth.mobile}`)};
  width: ${({ theme }) => theme.dimensions.menuWidth.mobile};
  box-shadow: ${({ theme }) => theme.colors.shadowNav};
  padding-top: ${({ theme }) => theme.dimensions.headerHeight.mobile};
  transition: ${({ theme }) => theme.transition.standard};

  ${mq(breakpoints => breakpoints.tablet)} {
    left: 0;
    width: ${({ theme }) => theme.dimensions.menuWidth.tablet};
    padding-top: ${({ theme }) => theme.dimensions.headerHeight.tablet};
  }

  ${mq(breakpoints => breakpoints.desktop)} {
    width: ${({ theme }) => theme.dimensions.menuWidth.desktop};
  }
`

Styling.Scrollable = styled.div`
  height: calc(100% - 67px);
  overflow: auto;
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.menuActive}`};
`

Styling.Button = styled.button`
  all: unset;
  display: grid;
  outline: none;
  align-items: center;
  width: ${({ theme }) => theme.dimensions.menuWidth.mobile};
  grid-template-columns: ${({ theme }) => `${theme.dimensions.distance.mobile} ${theme.dimensions.menuIcon.mobile} ${theme.dimensions.distance.mobile} 1fr ${theme.dimensions.distance.mobile}`};
  height: ${({ theme }) => theme.dimensions.menuButtonHeight.mobile};
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.menuActive}`};
  font-weight: ${({ theme, active }) => theme.font.weight[(active ? 'semibold' : 'light')]};
  font-size: ${({ theme }) => theme.font.size.menu.mobile};
  background: ${({ theme, active }) => (active ? theme.colors.menuActive : theme.colors.menuNorm)};
  transition: ${({ theme }) => theme.transition.standard};
  color: ${({ theme, active }) => theme.colors[active ? 'iconActive' : 'iconNorm']};

  &:hover {
    background: ${({ theme }) => theme.colors.menuHover};
  }
  & > svg {
    width: ${({ theme }) => theme.dimensions.menuIcon.mobile} !important;
    height: ${({ theme }) => theme.dimensions.menuIcon.mobile} !important;
    grid-column: 2 / 3;
    ${mq(breakpoints => breakpoints.desktop)} {
      margin-bottom: 5px;
    }
  }
  & > div {
    color: ${({ theme }) => theme.colors.default};
    grid-column: 4 / 5;
  } 

  ${mq(breakpoints => breakpoints.desktop)} {
    display: block;
    width: ${({ theme }) => theme.dimensions.menuWidth.desktop};
    height: ${({ theme }) => theme.dimensions.menuButtonHeight.desktop};
    text-align: center;
  }
`

Styling.Brand = styled.footer`
  left: 40px;
  width: 100px;
  height: 43px;
  bottom: 12px;
  display: block;
  position: absolute;
  background: ${({ theme }) => theme.colors.gradientBueth};

  ${mq(breakpoints => breakpoints.desktop)} {
    left: 25px;
  }
`

export default Styling
