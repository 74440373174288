import React from 'react'
import Styling from './Styling'
import Structure from '../Structure'
import useAuth from '../../../../customHooks/useAuth'
import Todos from './Todos'
import Notes from './Notes'

// import organizers from '../../../../lib/organizers.json'
// import { firestore } from '../../../../lib/firebase'

export default function Dashboard() {
  const { currentUser } = useAuth()
  const { displayName } = currentUser
  // const { db, doc, setDoc } = firestore
  const foo = displayName.split(' ')
  const name = foo.length > 1 ? foo[0] : displayName
  const hour = new Date().getHours()
  let salutation = 'Guten Tag'
  if (hour < 11) {
    salutation = 'Guten Morgen'
  } else if (hour > 18) {
    salutation = 'Guten Abend'
  }

  /* useEffect(() => {
    Object.keys(organizers).forEach((organizer) => {
      console.log(organizer, organizers[organizer])
      setDoc(doc(db, 'organizers', organizer), organizers[organizer], { merge: true })
    })
  }, []) */

  return (
    <Structure>
      <Structure.ContentHeader>
        <Structure.Section>
          {salutation} {name}
        </Structure.Section>
        <Structure.Interaction> </Structure.Interaction>
      </Structure.ContentHeader>
      <Structure.Content>
        <Styling>
          <Styling.Card>
            <Todos />
          </Styling.Card>
          <Styling.Card>
            <Notes />
          </Styling.Card>
        </Styling>
      </Structure.Content>
    </Structure>
  )
}
