import React from 'react'

const Logo = ({ volta, victoria, garden, color: extColor, width, height }) => {
    const color = extColor || 'currentColor'

    return (
        <svg
            viewBox="0 0 100 71"
            height={100}
            width={71}
            xmlSpace="preserve"
            id="volta_victoria_logo"
            style={{
                width: width || 'auto',
                height: height || 'auto',
            }}
        >
            {!victoria && (
                <path fill={color} d="M52.398,69.502c-0.552,0-1.068-0.213-1.454-0.599L31.095,49.056c-0.386-0.386-0.599-0.903-0.599-1.454
        s0.212-1.068,0.599-1.454L75.895,1.349c0.387-0.386,0.903-0.599,1.454-0.599c0.552,0,1.068,0.212,1.454,0.599l19.849,19.848
        c0.802,0.802,0.802,2.106,0,2.908L53.853,68.903C53.467,69.289,52.949,69.502,52.398,69.502z"/>
            )}
            <path fill={color} d="M77.349,1.5c0.352,0,0.68,0.134,0.924,0.379l19.849,19.848c0.509,0.509,0.509,1.338,0,1.848L53.322,68.372
        c-0.244,0.245-0.573,0.38-0.924,0.38c-0.352,0-0.68-0.135-0.924-0.379L31.625,48.525c-0.244-0.245-0.379-0.573-0.379-0.924
        s0.135-0.679,0.379-0.924L76.425,1.879C76.67,1.635,76.998,1.5,77.349,1.5 M77.349,0c-0.719,0-1.438,0.272-1.984,0.818
        L30.565,45.617c-1.091,1.091-1.091,2.877,0,3.969l19.849,19.848c0.545,0.545,1.265,0.818,1.984,0.818
        c0.719,0,1.438-0.273,1.984-0.818l44.799-44.798c1.091-1.091,1.091-2.877,0-3.969L79.333,0.818C78.787,0.272,78.068,0,77.349,0
        L77.349,0z"/>
            {!garden && (
                <path fill={color} d="M26.645,43.749c-0.551,0-1.068-0.212-1.454-0.599l-7.162-7.161c-0.386-0.386-0.599-0.903-0.599-1.454
        s0.212-1.068,0.599-1.454L48.84,2.27c0.386-0.386,0.903-0.599,1.454-0.599c0.552,0,1.068,0.212,1.455,0.599l7.16,7.161
        c0.387,0.386,0.599,0.903,0.599,1.454s-0.212,1.068-0.599,1.454L28.099,43.15C27.712,43.537,27.196,43.749,26.645,43.749z"/>
            )}
            <path fill={color} d="M50.294,2.421c0.352,0,0.68,0.135,0.924,0.379l7.16,7.161c0.245,0.244,0.379,0.572,0.379,0.923s-0.134,0.679-0.379,0.924
        L27.568,42.62c-0.245,0.244-0.573,0.379-0.924,0.379s-0.679-0.135-0.924-0.379l-7.162-7.161c-0.509-0.509-0.509-1.338,0-1.848
        L49.371,2.8C49.615,2.556,49.943,2.421,50.294,2.421 M50.294,0.921c-0.719,0-1.439,0.273-1.984,0.818L17.499,32.551
        c-1.091,1.091-1.091,2.877,0,3.969l7.162,7.161c0.546,0.545,1.265,0.818,1.984,0.818s1.439-0.273,1.984-0.818l30.811-30.812
        c1.092-1.091,1.092-2.877,0-3.969l-7.16-7.161C51.733,1.194,51.014,0.921,50.294,0.921L50.294,0.921z"/>
            {!volta && (
                <path fill={color} d="M13.578,30.683c-0.551,0-1.067-0.212-1.454-0.599L1.349,19.309c-0.386-0.386-0.599-0.903-0.599-1.454
        s0.212-1.068,0.599-1.454L14.771,2.979c0.386-0.386,0.902-0.599,1.454-0.599c0.551,0,1.068,0.212,1.454,0.599l10.774,10.776
        c0.802,0.802,0.802,2.106,0,2.908L15.032,30.084C14.646,30.47,14.129,30.683,13.578,30.683z"/>
            )}
            <path fill={color} d="M16.225,3.13c0.351,0,0.679,0.135,0.924,0.379l10.774,10.776c0.245,0.245,0.379,0.573,0.379,0.924
        s-0.135,0.679-0.379,0.924L14.501,29.554c-0.245,0.244-0.573,0.379-0.924,0.379c-0.351,0-0.679-0.135-0.923-0.379L1.879,18.779
        c-0.509-0.509-0.509-1.338,0-1.848L15.301,3.509C15.545,3.265,15.874,3.13,16.225,3.13 M16.225,1.63
        c-0.72,0-1.438,0.273-1.984,0.818L0.818,15.871c-1.091,1.091-1.091,2.877,0,3.969l10.775,10.775
        c0.546,0.545,1.265,0.818,1.984,0.818s1.438-0.273,1.984-0.818l13.421-13.421c1.091-1.091,1.091-2.877,0-3.969L18.209,2.449
        C17.663,1.903,16.944,1.63,16.225,1.63L16.225,1.63z"/>
        </svg>
    )
}

export default Logo
