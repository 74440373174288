import styled from 'styled-components'
import { Link } from 'react-router-dom'

const gap = {
  mini: '5px',
  small: '15px',
  large: '30px',
  max: '100px',
}
const size = {
  small: '35vw',
  large: '222px',
}

export const TicketsLink = styled.div`
  > a {
    color: #000;
    text-decoration: none;
    border: 1px solid #000;
    padding: 2px 8px;
    border-radius: 5px;
    transition: 0.2s all;
    background-color: #fff;

    &:hover {
      background-color: #616a71;
      color: #fff;
    }
  }
`

export const Bottom = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;

  @media (min-width: 640px) {
    align-items: flex-end;
    flex-direction: row;
    gap: ${gap.mini};
  }
`

export const Center = styled.h3`
  text-align: center;
  padding: 30px;
`

export const ClubName = styled.div``

export const ArtistSupport = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;

  display: block;
  display: -webkit-box;
  max-width: 100%;
  max-height: 28px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const TourName = styled.div`
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;

  display: block;
  display: -webkit-box;
  max-width: 100%;
  max-height: 36px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Label = styled(Link)`
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  font-weight: 700;
  text-decoration: none;
  color: #000;

  display: block;
  display: -webkit-box;
  max-width: 100%;
  max-height: 44px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: 0.3s opacity;
  opacity: 1;
  &:hover {
    opacity: 0.5;
  }
`

export const ExtraInfo = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #f00;
  text-align: left;
`

export const Content = styled.div`
  // background: red;
  display: flex;
  gap: ${gap.mini};
  flex-direction: column;
  text-decoration: none;
  grid-row: 2 / 3;
  grid-column: 2 / 3;

  @media (min-width: 640px) {
    margin-top: 34px;
  }

  @media (min-width: 768px) {
    margin-top: 0;
    align-self: center;
    grid-row: 1 / 1;
    grid-column: 3 / 4;
    padding-top: ${gap.small};
    padding-bottom: ${gap.small};
    gap: ${gap.small};
  }
`

export const EventDate = styled.div`
  // background: gold;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  white-space: nowrap;

  grid-row: 1 / 2;
  grid-column: 2 / 3;

  @media (min-width: 768px) {
    grid-row: 1 / 1;
    grid-column: 1 / 2;
    align-self: center;
  }
`

export const Image = styled(Link)`
  // background-color: green;
  text-decoration: none;
  align-self: center;

  grid-row: 1 / 3;
  grid-column: 1 / 2;

  @media (min-width: 768px) {
    grid-row: 1 / 1;
    grid-column: 2 / 3;
  }

  & > div {
    display: block;
    width: ${size.small};
    height: ${size.small};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: calc(var(--m) - var(--s) * 0.5);
    clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);

    @media (min-width: 640px) {
      width: ${size.large};
      height: ${size.large};
    }

    transition: 0.3s opacity;
    opacity: 1;
    &:hover {
      opacity: 0.7;
    }
  }
`

const Event = styled.div`
  display: grid;
  grid-gap: ${gap.small};
  row-gap: 0;
  text-decoration: none;
  color: #000;
  border-top: 2px solid #000;
  grid-template-columns: ${size.small} 1fr;
  grid-template-rows: 30px 1fr;
  margin-top: ${gap.small};
  padding-top: ${gap.small};

  &:first-of-type {
    margin-top: ${gap.large};
  }

  &:last-of-type {
    padding-bottom: ${gap.small};
    border-bottom: 2px solid #000;
  }

  @media (min-width: 640px) {
    margin-left: ${gap.small};
    margin-right: ${gap.small};
    grid-template-columns: ${size.large} 1fr;
  }

  @media (min-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    grid-gap: ${gap.large};
    row-gap: ${gap.mini};
    margin-top: ${gap.mini};
    padding-top: ${gap.mini};
    grid-template-columns: 135px ${size.large} 1fr;
    grid-template-rows: 1fr;
  }

  @media (min-width: 1000px) {
    grid-gap: ${gap.max};
  }
`

export default Event
