import React, { useState, useEffect, useCallback } from 'react'
import Faq from 'react-faq-component'

import { firestore } from '../../../lib/firebase'
import Wrapper from './Styling'

const FaqSection = () => {
  const [faqs, setFaqs] = useState(undefined)
  const { db, collection, getDocs, query } = firestore

  const getFaqs = useCallback(async () => {
    const u = []
    const q = query(collection(db, 'faqs'))
    await getDocs(q).then((querySnapshot) =>
      querySnapshot.forEach((doc) => {
        u.push({ ref: doc.ref, ...doc.data() })
      }),
    )
    setFaqs(u[0].faqs)
  }, [collection, db, getDocs, query])

  useEffect(() => {
    getFaqs()
  }, [getFaqs])

  return !faqs ? undefined : (
    <Wrapper>
      <Faq
        data={{ rows: faqs }}
        styles={{
          transitionDuration: '0.3s',
          timingFunc: 'ease-in',
        }}
        config={{
          tabFocus: true,
        }}
      />
    </Wrapper>
  )
}

export default FaqSection
