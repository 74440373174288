import * as React from "react"
import { Link } from 'react-router-dom'

import logo from './Logo_Victoria-Volta_solo.png'
// import logo from './Logo_Victoria-Volta_rainbow.png' //pride

function SvgComponent(props) {
  return (
    <Link to='/news'>
      <img src={logo} width='100%' alt='Logo' />
    </Link>
  )
}

export default SvgComponent
