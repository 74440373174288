import React, { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Button, ButtonGroup, Container, Col, Form, InputGroup, Row } from 'react-bootstrap'

import { MdDragHandle, MdModeEditOutline, MdOutlineDelete } from 'react-icons/md'

import useMenu from '../../../../customHooks/useMenu'
import { firestore } from '../../../../lib/firebase'

import Structure from '../Structure'
import Styling from './Styling'

export default function Dashboard() {
  const { loading, setLoading } = useMenu()
  const { db, collection, getDocs, query, doc, setDoc } = firestore

  const [modIndex, setModIndex] = useState(-1)
  const [modTitle, setModTitle] = useState('')
  const [modContent, setModContent] = useState('')
  const [faqs, setFaqs] = useState([])

  function handleOnDragEnd(result) {
    if (!result.destination) return

    const items = Array.from(faqs)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)

    setFaqs(items)
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: '16px',
    margin: `0 0 8px 0`,
    background: isDragging ? 'lightgreen' : 'white',
    ...draggableStyle,
  })

  const fetchData = useCallback(async () => {
    const u = []
    const q = query(collection(db, 'faqs'))
    await getDocs(q).then((querySnapshot) =>
      querySnapshot.forEach((doc) => {
        u.push({ ref: doc.ref, ...doc.data() })
      }),
    )
    setFaqs(u[0].faqs)
    setLoading(false)
  }, [collection, db, getDocs, query, setLoading])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchData()
  }, [fetchData])

  const save = useCallback(() => {
    setDoc(doc(db, 'faqs', 'all'), { faqs })
  }, [db, doc, faqs, setDoc])

  const reset = useCallback(() => {
    setModIndex(-1)
    setModTitle('')
    setModContent('')
  }, [])

  const agree = useCallback(() => {
    faqs[modIndex] = { title: modTitle, content: modContent }
    setModIndex(undefined)
    setModTitle('')
    setModContent('')
  }, [faqs, modContent, modIndex, modTitle])

  const create = useCallback(() => {
    setFaqs((current) => [{ title: 'Neue FAQ', content: '' }, ...current])
  }, [])

  const del = useCallback((idx) => {
    setFaqs((current) => {
      const f = [...current]
      f.splice(idx, 1)
      return f
    })
  }, [])

  useEffect(() => {
    setModTitle(faqs[modIndex]?.title)
    setModContent(faqs[modIndex]?.content)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modIndex])

  return (
    <Structure>
      <Structure.ContentHeader>
        <Structure.Section>Frequently&nbsp;Asked&nbsp;Questions</Structure.Section>
        <Structure.Interaction>
          <div style={{ textAlign: 'right' }}>
            <Button variant="primary" onClick={create}>
              FAQ anlegen
            </Button>
          </div>
        </Structure.Interaction>
      </Structure.ContentHeader>
      <Structure.Content>
        <Styling>
          <Styling.Card>
            {loading ? (
              'Loading...'
            ) : (
              <>
                <div
                  style={{
                    height: 'calc(100vh - 270px)',
                    overflow: 'auto',
                  }}
                >
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <ul
                          style={{
                            listStyle: 'none',
                            paddingLeft: 0,
                          }}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {faqs.map((faq, index) => {
                            return (
                              <Draggable key={`key-${index}`} draggableId={`id-${index}`} index={index}>
                                {(provided, snapshot) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={{
                                      border: 'solid 2px #d0d0d0',
                                      padding: '.5em .8em .5em .5em',
                                      marginBottom: '1em',
                                      ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <div style={{ flex: 0 }} {...provided.dragHandleProps}>
                                        <MdDragHandle className="m-2" style={{ fontSize: '20px' }} />
                                      </div>
                                      <div className="p-2" style={{ flex: 1 }}>
                                        {faq.title}
                                      </div>
                                      <button style={{ flex: 0, border: 0, background: 'none' }}>
                                        <MdOutlineDelete
                                          onClick={() => del(index)}
                                          className="m-2"
                                          style={{ fontSize: '20px' }}
                                        />
                                      </button>
                                      <button style={{ flex: 0, border: 0, background: 'none' }}>
                                        <MdModeEditOutline
                                          onClick={() => setModIndex(index)}
                                          className="m-2"
                                          style={{ fontSize: '20px' }}
                                        />
                                      </button>
                                    </div>
                                    {modIndex === index && (
                                      <Container>
                                        <Row>
                                          <Col xs={12}>
                                            <InputGroup className="my-2">
                                              <InputGroup.Text style={{ width: '150px' }}>Frage</InputGroup.Text>
                                              <Form.Control
                                                placeholder=""
                                                value={modTitle}
                                                onChange={(e) => setModTitle(e.target.value)}
                                              />
                                            </InputGroup>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={12}>
                                            <Form.Group>
                                              <Form.Label>Antwort</Form.Label>
                                              <Form.Control
                                                as="textarea"
                                                rows={3}
                                                value={modContent}
                                                onChange={(e) => setModContent(e.target.value)}
                                              />
                                            </Form.Group>
                                          </Col>
                                        </Row>
                                        <Row>
                                          <Col xs={8}>&nbsp;</Col>
                                          <Col xs={4}>
                                            <ButtonGroup className="my-2 mt-3 w-100">
                                              <Button variant="danger" onClick={reset}>
                                                Abbrechen
                                              </Button>
                                              <Button variant="success" onClick={agree}>
                                                Übernehmen
                                              </Button>
                                            </ButtonGroup>
                                          </Col>
                                        </Row>
                                      </Container>
                                    )}
                                  </li>
                                )}
                              </Draggable>
                            )
                          })}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </>
            )}
            <Container>
              <Row>
                <Col xs={9}>&nbsp;</Col>
                <Col xs={3}>
                  <Button variant="success" onClick={save} className="my-2 mt-3 w-100">
                    Alles speichern
                  </Button>
                </Col>
              </Row>
            </Container>
          </Styling.Card>
        </Styling>
      </Structure.Content>
    </Structure>
  )
}
