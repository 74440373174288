/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useParams, Route, useHistory } from 'react-router-dom'
import { Form, InputGroup, Button } from 'react-bootstrap'
import smoothscroll from 'smoothscroll-polyfill'

import Header from './Header'
import Logo from './Logo'

import Wrapper, { LogoWrapper, SuperHeaderTitle, ContentSection } from './Styling'

import { firestore } from '../../lib/firebase'

import Diamonds from './Diamonds'
// import NewsSection from './NewsSection'
import ProgramSection from './ProgramSection'
import InfoSection from './InfoSection'
import AnfahrtSection from './AnfahrtSection'
import FaqSection from './FaqSection'
import Contact from './Contact'

import NewsModal from './NewsModal'
import EventModal from './EventModal'

import CookieBanner from './CookieBanner'

import victoriaVolta from './victoria-volta.png'

const CarlswerkVictoria = () => {
  smoothscroll.polyfill()

  const numEvents = 20

  const history = useHistory()
  const { scrollTo, slug, previousId, previousSlug } = useParams()

  const [scrollTopCalc, setScrollTopCalc] = useState(0)
  const [scrollTop, setScrollTop] = useState(slug ? 101 : 0)

  const [events, setEvents] = useState(undefined)

  const [results, setResults] = useState(undefined)
  const [lastVisible, setLastVisible] = useState(undefined)
  const [nextVisible, setNextVisible] = useState(false)
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState('')
  const [privacyOpen, setPrivacyOpen] = useState(false)

  if (previousId && previousSlug) {
    history.push(`/event/${previousId}/${previousSlug}`)
  }

  const { db, collection, getDocs, query, where, limit, startAfter } = firestore
  const refs = {
    suche: useRef(),
    programm: useRef(),
    anfahrt: useRef(),
    faq: useRef(),
    info: useRef(),
    kontakt: useRef(),
  }

  const onScroll = useCallback(() => {
    const currentPosition = window.pageYOffset
    setScrollTopCalc(currentPosition <= 0 ? 0 : currentPosition)
  }, [])

  const validate = (events) => {
    const valid = events.filter((e) => e.acts)
    valid.map((event) => {
      const ep = event.acts.filter(({ type }) => type === 'eventpicture')
      const ma = event.acts.filter(({ type }) => type === 'main')
      event.mainacts = ma.length > 0 ? ma : event.acts
      event.specials = event.acts.filter(({ type }) => type === 'special')
      event.supports = event.acts.filter(({ type }) => type === 'support')
      event.image = ep?.[0]?.images?.[0]?.src || event.mainacts[0]?.images?.[0]?.src || ''
      event.main = event.mainacts.reduce((acc, act) => [...acc, act.name], []).join(', ')
      event.special = event.specials.reduce((acc, act) => [...acc, act.name], []).join(', ')
      event.support = event.supports.reduce((acc, act) => [...acc, act.name], []).join(', ')
      return event
    })

    return valid
  }

  const prepare = (events) =>
    events.map((event) => {
      const ma = event.acts.filter(({ type }) => type === 'main')
      const mainacts = ma.length > 0 ? ma : event.acts
      const specials = event.acts.filter(({ type }) => type === 'special')
      const supports = event.acts.filter(({ type }) => type === 'support')
      const main = mainacts.reduce((acc, act) => [...acc, act.name], []).join(', ')
      const special = specials.reduce((acc, act) => [...acc, act.name], []).join(', ')
      const support = supports.reduce((acc, act) => [...acc, act.name], []).join(', ')
      event.search = `${event.label} ${main} ${special} ${support} ${event?.tour || ''}`.toLowerCase()
      return event
    })

  const getSearchResults = useCallback(() => {
    if (results) {
      return
    }
    const searchQuery = query(
      collection(db, 'events'),
      where('date', '>=', new Date().toISOString().slice(0, 10)),
      where('online', '==', true),
    )

    getDocs(searchQuery)
      .then((querySnapshot) => {
        const res = []
        querySnapshot.forEach((doc) => {
          const data = {
            date: '', // immer leer initialisieren
            begin: '',
            online: false,
            ...doc.data(),
          }
          res.push({ id: doc.id, ...data })
        })
        return validate(res)
      })
      .then((res) => setResults(prepare(res)))
  }, [])

  useEffect(() => {
    if (search.length > 2 && !results) {
      getSearchResults()
    }
  }, [search])

  const filtered = useMemo(() => {
    if (search.length > 2 && results) {
      return results.filter((r) => (r?.search || '').includes(search.toLowerCase()))
    }
    return []
  }, [search, results])

  const getEvents = useCallback(() => {
    const firstQuery = query(
      collection(db, 'events'),
      where('date', '>=', new Date().toISOString().slice(0, 10)),
      where('online', '==', true),
      limit(numEvents),
    )
    getDocs(firstQuery)
      .then((querySnapshot) => {
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1])
        const res = []
        querySnapshot.forEach((doc) => {
          const data = {
            date: '', // immer leer initialisieren
            begin: '',
            ...doc.data(),
          }
          res.push({ id: doc.id, ...data })
        })
        return validate(res)
      })
      .then((res) => {
        setEvents(res)
        if (res.length === numEvents) {
          setNextVisible(true)
        }
      })
  }, [])

  const next = useCallback(() => {
    if (lastVisible) {
      const nextQuery = query(
        collection(db, 'events'),
        where('date', '>=', new Date().toISOString().slice(0, 10)),
        where('online', '==', true),
        startAfter(lastVisible),
        limit(numEvents),
      )
      getDocs(nextQuery)
        .then((querySnapshot) => {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1])
          const res = []
          querySnapshot.forEach((doc) => {
            const data = {
              date: '', // immer leer initialisieren
              begin: '',
              online: false,
              ...doc.data(),
            }
            res.push({ id: doc.id, ...data })
          })
          return validate(res)
        })
        .then((res) => {
          setEvents((current) => [...current, ...res])
          if (res.length < numEvents) {
            setNextVisible(false)
          }
        })
    }
  }, [lastVisible])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    setOpen('')
    window.scrollTo({
      top: refs?.[scrollTo]?.current
        ? refs[scrollTo].current.getBoundingClientRect().top + window.pageYOffset - 190
        : 0,
      behavior: 'smooth',
    })
  }, [scrollTo])

  useEffect(() => {
    if (slug || (scrollTop < 100 && scrollTopCalc > 100)) {
      setScrollTop(101)
    } else if (scrollTop > 100 && scrollTopCalc < 100) {
      setScrollTop(0)
    }
  }, [scrollTopCalc])

  useEffect(() => setScrollTop(window.scrollY > 100 || slug ? 101 : 0), [slug])

  useEffect(() => {
    window.setTimeout(() => {
      if (!events) {
        getEvents()
      }
    }, 2500)

    getEvents()
  }, [])

  // console.log({ news, updates, events, organizers, faqs })

  return (
    <>
      <Wrapper>
        <SuperHeaderTitle data-scrolltop={scrollTop} to="/news">
          <div>
            <img src={victoriaVolta} border="0" alt="logo" />
          </div>
        </SuperHeaderTitle>

        <Header scrollTop={scrollTop} slug={slug} open={open} setOpen={setOpen} />

        <Diamonds />

        <ContentSection title="Suche">
          <div ref={refs.suche}>
            <InputGroup className="my-3" style={{ maxWidth: '600px', margin: 'auto' }}>
              <Form.Control
                size="lg"
                type="text"
                placeholder="Was suchst du?"
                value={search}
                onChange={(e) => setSearch(e.currentTarget.value)}
                style={{ outline: 0 }}
              />
              <InputGroup.Text style={{ background: '#fff' }}>{`${filtered.length} Treffer`}</InputGroup.Text>
              <Button
                onClick={() => setSearch('')}
                variant="outline-secondary"
                style={{ borderColor: '#ced4da', zIndex: 0 }}
              >
                Reset
              </Button>
            </InputGroup>
            {search.length > 2 && <ProgramSection getEvents={getEvents} events={filtered} />}
          </div>
        </ContentSection>

        {search.length <= 2 && (
          <ContentSection title="Programm">
            <div ref={refs.programm}>
              <ProgramSection getEvents={getEvents} events={events ?? []} />
              {nextVisible && (
                <Button
                  onClick={next}
                  variant="outline-secondary"
                  style={{ width: '100%', maxWidth: '600px', margin: '32px auto', display: 'block' }}
                >
                  Weitere Programmpunkte laden...
                </Button>
              )}
            </div>
          </ContentSection>
        )}

        <ContentSection title="Info">
          <div ref={refs.info}>
            <InfoSection />
          </div>
        </ContentSection>

        <ContentSection title="Anfahrt">
          <div ref={refs.anfahrt}>
            <AnfahrtSection />
          </div>
        </ContentSection>

        <ContentSection title="FAQ">
          <div ref={refs.faq}>
            <FaqSection />
          </div>
        </ContentSection>

        <ContentSection title="Kontakt" className="last">
          <div ref={refs.kontakt}>
            <Contact privacyOpen={privacyOpen} setPrivacyOpen={setPrivacyOpen} />
          </div>
        </ContentSection>

        <Route exact path="/news/:slug" component={NewsModal} />
        <Route exact path="/event/:previousId/:previousSlug" component={() => <EventModal />} />
        <Route exact path="/event/:slug" component={() => <EventModal />} />

        <LogoWrapper data-scrolltop={scrollTop}>
          <Logo />
        </LogoWrapper>

        <CookieBanner setPrivacyOpen={setPrivacyOpen} />
      </Wrapper>
    </>
  )
}

export default CarlswerkVictoria
