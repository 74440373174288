import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  padding: 15px;
  color: #000;
  display: flex;
  border-top: 1px solid #000;
`

export const Text = styled.div`
  font-size: 14px;
`

export const ButtonWrapper = styled.div`
  padding: 5px 0 5px 15px;
  width: 10%;
  min-width: 80px;
`

export default Wrapper
