import React from 'react'
import { GoMail as ContactIcon } from 'react-icons/go'
import { ImMap2 as FlyerIcon, ImCodepen as MapIcon } from 'react-icons/im'
import { RiSoundModuleFill as TechIcon } from 'react-icons/ri'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'

import Wrapper, {
  CenterLinks,
  ClubAreaVictoria,
  ClubAreaVolta,
  ClubGrid,
  GalleryWrapper,
  Link,
  MailTo,
  //SubTextArea,
  SubText,
  TextArea,
} from './Styling'

import biergarten1 from './biergarten1.jpg'
import biergarten2 from './biergarten2.jpg'
import victoria1 from './victoria1.jpg'
import victoria2 from './victoria2.jpg'
import volta1 from './volta1.jpg'
import volta2 from './volta2.jpg'

const PREFIX_URL = 'https://firebasestorage.googleapis.com/v0/b/carlswerk-victoria.appspot.com/o/info'

const images = [
  {
    original: volta1,
    description: 'Club Volta von der Bühne Richtung Bar',
  },
  {
    original: volta2,
    description: 'Club Volta von der Bar Richtung Bühne',
  },
  {
    original: biergarten1,
    description: 'Biergarten in Richtung Club Volta',
  },
  {
    original: biergarten2,
    description: 'Biergarten mit Aussenbar',
  },
  {
    original: victoria1,
    description: 'Carlswerk Victoria in Richtung der Bühne',
  },
  {
    original: victoria2,
    description: 'Carlswerk Victoria mit Bankettbestuhlung',
  },
]

const InfoSection = () => {
  return (
    <Wrapper>
      <ClubGrid>
        <ClubAreaVolta />
        <TextArea>
          Auf dem ehemaligen Werksgelände des Kölner Kabelherstellers Felten &amp; Guillaume ist ein neues urbanes Areal
          gewachsen, das heute den Namen Carlswerk trägt.
          <br />
          <br />
          Hier befinden sich auch die beiden Veranstaltungshallen <strong>Carlswerk Victoria</strong> und{' '}
          <strong>Club Volta</strong>, die durch einen attraktiven <strong>Biergarten</strong> miteinander verbunden
          sind.
        </TextArea>
        <CenterLinks>
          <Link
            href={`${PREFIX_URL}%2FVictoriaVolta_Plan_web.pdf?alt=media&token=b3ce7df3-1e80-4070-af57-a8b04daea3e7`}
            rel="noopener noreferrer"
            target="_blank"
            download
          >
            <MapIcon />
            Hallenplan
          </Link>
          <Link
            href={`${PREFIX_URL}%2FMappe_VV_schwarz.pdf?alt=media&token=07ec4df3-1d79-446c-b781-1a1033a41fb8`}
            rel="noopener noreferrer"
            target="_blank"
            download
          >
            <FlyerIcon />
            HQ Flyer
          </Link>
        </CenterLinks>
        <ClubAreaVictoria />
        <MailTo
          to="#"
          className="volta-booking"
          onClick={(e) => {
            e.preventDefault()
            window.location = 'mailto:booking@allesgute.live?subject=Buchungsanfrage%20Club%20Volta'
          }}
        >
          <ContactIcon />
          Booking
        </MailTo>
        <Link
          href={`${PREFIX_URL}%2FVolta_VenueSheet_0922_web.pdf?alt=media&token=5c335d01-2f82-4f08-be6b-1f00ef900479`}
          rel="noopener noreferrer"
          target="_blank"
          download
          className="volta-tech"
        >
          <TechIcon />
          TechSpecs
        </Link>
        <MailTo
          to="#"
          className="victoria-booking"
          onClick={(e) => {
            e.preventDefault()
            window.location = 'mailto:booking@allesgute.live?subject=Buchungsanfrage%20Carlswerk%20Victoria'
          }}
        >
          <ContactIcon />
          Booking
        </MailTo>
        <Link
          href={`${PREFIX_URL}%2FVictoria_VenueSheet_0423_web.pdf?alt=media&token=f594ad42-ebab-4f7a-9c7a-0b4176f5b044`}
          rel="noopener noreferrer"
          target="_blank"
          download
          className="victoria-tech"
        >
          <TechIcon />
          TechSpecs
        </Link>
      </ClubGrid>
      <GalleryWrapper>
        <ImageGallery
          autoPlay
          lazyLoad
          showBullets
          items={images}
          slideDuration={300}
          slideInterval={5000}
          showThumbnails={false}
          showPlayButton={false}
          showFullscreenButton={false}
        />
      </GalleryWrapper>
      <SubText>
        Nur wenige hundert Meter von der ÖPNV-Anbindung entfernt finden auf dem Gelände der ehemaligen Wickelei
        Veranstaltungen verschiedenster Art statt, wie z.B. Konzerte, Firmenevents, private Feiern, Märkte, Messen,
        Kongresse oder Parties. Das <strong>Carlswerk Victoria</strong>, der <strong>Club Volta</strong> und der{' '}
        <strong>Biergarten</strong> können dabei einzeln genutzt werden, sind aber auch flexibel für den Bedarf
        vielfältigster Events kombinierbar.
      </SubText>
    </Wrapper>
  )
}

export default InfoSection
