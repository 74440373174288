const slugify = (txt) => txt
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 -]/g, '')
    .replace(/--+/g, '-')
    .replace(/\s+/g, '-')

export default slugify