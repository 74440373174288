import React, { useCallback, useState, useEffect } from 'react'

import { Button, Container, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { MdAdd as IconAdd } from 'react-icons/md'

import { firestore } from '../../../../lib/firebase'
import Structure from '../Structure'
import Styling from './Styling'

const initObject = {
  phone: '',
  person: '',
  no: '',
  mail: '',
  website: '',
  zip: '',
  category: '',
  street: '',
  city: '',
}

function OrganizerList({ organizers, selectedName, setSelectedName }) {
  return (
    <Styling.Card>
      <Styling.OrganizerList>
        {organizers.length === 0 ? (
          <Styling.OrganizerListEntry disabled>
            <em>Keine geplanten Events</em>
          </Styling.OrganizerListEntry>
        ) : (
          <>
            {organizers.map((o) => (
              <Styling.OrganizerListEntry
                key={o.name}
                active={selectedName === o.name}
                onClick={() => setSelectedName(o.name)}
              >
                {o.name}
              </Styling.OrganizerListEntry>
            ))}
          </>
        )}
      </Styling.OrganizerList>
    </Styling.Card>
  )
}

export default function Organizers() {
  const { db, collection, getDocs, setDoc, doc } = firestore

  const [selectedName, setSelectedName] = useState(undefined)
  const [organizers, setOrganizers] = useState(undefined)
  const [organizer, setOrganizer] = useState(initObject)
  const [isLoading, setIsLoading] = useState(true)

  const getOrganizers = useCallback(async () => {
    getDocs(collection(db, 'organizers'))
      .then((querySnapshot) => {
        const res = []
        querySnapshot.forEach((doc) => {
          res.push({ name: doc.id, ...doc.data() })
        })
        return res.sort((a, b) => a.name.localeCompare(b.name))
      })
      .then(setOrganizers)
  }, [collection, db, getDocs])

  useEffect(() => {
    getOrganizers()
    setIsLoading(false)
  }, [getOrganizers])

  useEffect(() => {
    if (organizers && selectedName) {
      const x = (organizers ?? []).find(({ name }) => name === selectedName)
      setOrganizer({ ...initObject, ...x })
    } else {
      setOrganizer(initObject)
    }
  }, [organizers, selectedName])

  const createOrganizer = useCallback(async () => {
    const name = window.prompt(
      'Gib einen Namen für den Veranstalter ein.\nBeachte: Dieser kann später nicht mehr geändert werden.',
    )
    if (name?.length ?? 0 > 1) {
      setIsLoading(true)
      await setDoc(doc(db, 'organizers', name), {}, { merge: true })
      await getOrganizers()
      setSelectedName(name)
      setIsLoading(false)
    }
  }, [db, doc, getOrganizers, setDoc])

  const saveOrganizer = useCallback(async () => {
    if (selectedName) {
      const currentOrganizer = { ...organizer }
      delete currentOrganizer.name
      setIsLoading(true)
      await setDoc(doc(db, 'organizers', selectedName), currentOrganizer, { merge: true })
      setIsLoading(false)
    }
  }, [db, doc, organizer, selectedName, setDoc])

  return !organizers ? null : (
    <Structure>
      <Structure.ContentHeader>
        <Structure.Section>Veranstalter</Structure.Section>
        <Structure.Interaction>
          <Button variant="outline-primary" onClick={createOrganizer} size="sm">
            <IconAdd /> Veranstalter anlegen
          </Button>
        </Structure.Interaction>
      </Structure.ContentHeader>
      <Structure.Content>
        <Styling>
          <OrganizerList organizers={organizers} selectedName={selectedName} setSelectedName={setSelectedName} />
          {!selectedName || !organizer ? (
            <div />
          ) : (
            <Styling.OrganizerCard>
              <Container style={{ height: 'calc(100vh - 318px)', overflow: 'auto' }} className="my-2">
                <Row>
                  <Col xs={12}>
                    Der Name des Veranstalters kann nachträglich nicht mehr geändert werden.
                    <br />
                    Wenn die Website eingegeben wird, wird diese mit dem Namen verlinkt.
                    <br />
                    <br />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '200px' }}>&nbsp;Name</InputGroup.Text>
                      <Form.Control value={organizer.name} disabled />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '200px' }}>&nbsp;Website</InputGroup.Text>
                      <Form.Control
                        value={organizer.website}
                        onChange={(e) =>
                          setOrganizer((current) => ({
                            ...current,
                            website: e.target.value,
                          }))
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <br />
                    <hr />
                    Die folgenden Daten sind nur als Adressbuch zu verstehen und werden nicht auf der Website
                    dargestellt.
                    <br />
                    <br />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '200px' }}>&nbsp;Kategorie</InputGroup.Text>
                      <Form.Select
                        value={organizer.category}
                        onChange={(e) =>
                          setOrganizer((current) => ({
                            ...current,
                            category: e.target.options[e.target.selectedIndex].value,
                          }))
                        }
                      >
                        <option value="">Bitte wählen...</option>
                        <option value="Festival">Festival</option>
                        <option value="Konzerte">Konzerte</option>
                        <option value="Management">Management</option>
                        <option value="Management / Touring">Management / Touring</option>
                        <option value="Party">Party</option>
                        <option value="Tour">Tour</option>
                        <option value="Sonstige">Sonstige</option>
                      </Form.Select>
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '200px' }}>&nbsp;Mail</InputGroup.Text>
                      <Form.Control
                        value={organizer.mail}
                        onChange={(e) =>
                          setOrganizer((current) => ({
                            ...current,
                            mail: e.target.value,
                          }))
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '200px' }}>&nbsp;Telefon</InputGroup.Text>
                      <Form.Control
                        value={organizer.phone}
                        onChange={(e) =>
                          setOrganizer((current) => ({
                            ...current,
                            phone: e.target.value,
                          }))
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '200px' }}>&nbsp;Ansprechpartner</InputGroup.Text>
                      <Form.Control
                        value={organizer.person}
                        onChange={(e) =>
                          setOrganizer((current) => ({
                            ...current,
                            person: e.target.value,
                          }))
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '200px' }}>&nbsp;Straße</InputGroup.Text>
                      <Form.Control
                        value={organizer.street}
                        onChange={(e) =>
                          setOrganizer((current) => ({
                            ...current,
                            street: e.target.value,
                          }))
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '200px' }}>&nbsp;Hausnummer</InputGroup.Text>
                      <Form.Control
                        value={organizer.no}
                        onChange={(e) =>
                          setOrganizer((current) => ({
                            ...current,
                            no: e.target.value,
                          }))
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '200px' }}>&nbsp;Postleitzahl</InputGroup.Text>
                      <Form.Control
                        value={organizer.zip}
                        onChange={(e) =>
                          setOrganizer((current) => ({
                            ...current,
                            zip: e.target.value,
                          }))
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <InputGroup className="my-2">
                      <InputGroup.Text style={{ width: '200px' }}>&nbsp;Stadt</InputGroup.Text>
                      <Form.Control
                        value={organizer.city}
                        onChange={(e) =>
                          setOrganizer((current) => ({
                            ...current,
                            city: e.target.value,
                          }))
                        }
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col>
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col xs={9}>&nbsp;</Col>
                  <Col xs={3}>
                    <Button variant={'success'} onClick={saveOrganizer} className="my-2 w-100" disabled={isLoading}>
                      Save
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Styling.OrganizerCard>
          )}
        </Styling>
      </Structure.Content>
    </Structure>
  )
}
