import React from 'react'
import Wrapper, { IconLink, Link, MainGrid, NewsGrid, IconButton, MobileMenu, Container, MobileLink } from './Styling'
import { CgMenu as MenuIcon, CgClose as CloseIcon, CgSearch as SearchIcon, CgMail as ContactIcon } from 'react-icons/cg'

export default function Header({ scrollTop, slug, open, setOpen }) {
  return (
    <>
      <MobileMenu className={open}>
        <Container className={open}>
          <MobileLink to="/suche">Suche</MobileLink>
          <MobileLink to="/programm">Programm</MobileLink>
          <MobileLink to="/anfahrt">Anfahrt</MobileLink>
          <MobileLink to="/faq">FAQ</MobileLink>
          <MobileLink to="/info">Info</MobileLink>
          <MobileLink to="/kontakt">Kontakt</MobileLink>
        </Container>
      </MobileMenu>
      <Wrapper data-scrolltop={scrollTop}>
        {slug ? (
          <NewsGrid>
            <div />
            <div />
            <div />
            <IconLink
              to="/news"
              onClick={() => {
                document.body.style.overflow = 'auto'
              }}
            >
              <CloseIcon />
            </IconLink>
            <div />
          </NewsGrid>
        ) : (
          <MainGrid>
            <div />
            <IconButton
              onClick={() => {
                setOpen((current) => (current === 'show' ? '' : 'show'))
              }}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/suche" style={{ textAlign: 'left', width: '20px', height: '20px' }}>
              <SearchIcon style={{ width: '25px', height: '25px' }} />
            </Link>
            <div />
            <Link to="/programm" style={{ textAlign: 'center' }}>
              Programm
            </Link>
            <div />
            <Link to="/info" style={{ textAlign: 'center' }}>
              Info
            </Link>
            <div />
            <Link to="/anfahrt" style={{ textAlign: 'center' }}>
              Anfahrt
            </Link>
            <div />
            <Link to="/faq" style={{ textAlign: 'center' }}>
              FAQ
            </Link>
            <div />
            <Link to="/kontakt" style={{ textAlign: 'right' }}>
              <ContactIcon style={{ width: '25px', height: '25px' }} />
            </Link>
            <div />
          </MainGrid>
        )}
      </Wrapper>
    </>
  )
}
