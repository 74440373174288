import styled from 'styled-components'

export const Content = styled.div`
  color: #fff;
  font-size: 18px;
  line-height: 30px;
  min-height: 0;
  padding: 30px 0;

  & > a {
    color: #fff;
  }
`

export const Cols = styled.div`
  display: inline-block;
  margin: -40px 0 30px 0;

  @media (min-width: 640px) {
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

export const Imprint = styled.div`
  color: #fff;
  text-align: center;
  margin: 60px 0 -60px 0;

  & > button {
    background: transparent;
    color: #fff;
    border: 0;
  }

  & > div {
    transition: all 0.3s;
    overflow: hidden;
    height: 0;
    padding: 0 40px;

    &.open {
      height: auto;
      padding: 60px 0 0 0;
    }
  }
`

export const Top = styled.div`
  border-bottom: 1px solid black;
  padding-bottom: 40px;
  margin-bottom: 40px;
`

export const Bottom = styled.div`
  text-align: left;
  & > h3 {
    text-align: center;
  }
`

export const Partner = styled.div`
  & > div {
    max-width: 1000px;
    margin: auto;

    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (min-width: 640px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: 1000px) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }

    justify-content: center;
    align-items: center;

    grid-gap: 30px;
    left: 30px;
    right: 30px;

    & > a {
      display: block;
      margin: auto;

      & > img {
        width: 100%;
        max-width: 150px;
      }
    }
  }
`

const Wrapper = styled.div`
  max-width: 1000px;
  margin: auto;
  background: #000;
`

export default Wrapper
