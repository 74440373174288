import React, { Fragment, useState, useMemo } from 'react'

import { FcCancel as IconOffline, FcNext as IconClosed, FcExpand as IconOpened } from 'react-icons/fc'
import { Form } from 'react-bootstrap'
import Logo from '../../../shared/Logo'
import Styling from './Styling'

function EventLabel({ events, monthYear, eventId, setEventId }) {
  const [open, setOpen] = useState(false)
  const currentEvents = useMemo(() => events.filter((event) => event.monthYear === monthYear), [events, monthYear])

  return (
    <Fragment>
      <Styling.Button type="button" onClick={() => setOpen((current) => !current)}>
        {open ? <IconOpened /> : <IconClosed />}
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <div>{monthYear}</div>
          <div className="mx-2">{monthYear !== 'Vergangen' ? `(${currentEvents.length})` : ''}</div>
        </div>
      </Styling.Button>
      {open && (
        <Styling.EventList>
          {currentEvents.map((event) => (
            <Styling.EventListEntry key={event.id} active={eventId === event.id} onClick={() => setEventId(event.id)}>
              {event.online ? (
                <Logo volta={event.volta} garden={event.garden} victoria={event.victoria} width={27} height={22} />
              ) : (
                <IconOffline />
              )}
              {event.label || <em>Leeres Event</em>}
            </Styling.EventListEntry>
          ))}
        </Styling.EventList>
      )}
    </Fragment>
  )
}

function EventList({ events, labels, eventId, setEventId, limitEvents, setLimitEvents }) {
  return (
    <Styling.Card>
      <Form.Check
        type="switch"
        label="Liste auf 1 Jahr limitieren"
        onChange={(e) => setLimitEvents(e.target.checked)}
        checked={limitEvents}
        className="mb-3"
      />
      {events.length === 0 ? (
        <Styling.EventList>
          <Styling.EventListEntry disabled>
            <em>Keine geplanten Events</em>
          </Styling.EventListEntry>
        </Styling.EventList>
      ) : (
        <>
          {labels.map((monthYear) => (
            <EventLabel
              key={monthYear}
              monthYear={monthYear}
              events={events}
              eventId={eventId}
              setEventId={setEventId}
            />
          ))}
        </>
      )}
    </Styling.Card>
  )
}

export default EventList
