import React, { useRef, useEffect } from 'react'

import Wrapper, { Cols, Content, Imprint, Top, Bottom, Partner } from './Styling'

import s4logo from './4sound_night.png'
import nslogo from './naturstrom_night.png'
import frlogo from './frueh_night.png'
import bslogo from './bslogo.png'
import cblogo from './carlsberg_night.png'
import fklogo from './franziskaner_night.png'

export default function Contact({ privacyOpen, setPrivacyOpen }) {
  const button = useRef()

  useEffect(() => {
    if (privacyOpen) {
      button.current.scrollIntoView({ left: 0, block: 'start', behavior: 'smooth' })
    }
  }, [privacyOpen])

  return (
    <Wrapper>
      <Cols>
        <Content>
          <strong>AllesGute.Live GmbH</strong>
          <br />
          Schanzenstr. 6-20
          <br />
          51063 Köln
          <br />
          Gebäude: 2.11
          <br />
          <br />
          Tel.: +49 (0) 221 888 90 60
          <br />
          {/* Fax: +49 (0) 221 888 90 666 */}
          <br />
          <a href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;&#105;&#110;&#102;&#111;&#064;&#097;&#108;&#108;&#101;&#115;&#103;&#117;&#116;&#101;&#046;&#108;&#105;&#118;&#101;">
            &#105;&#110;&#102;&#111;&#064;&#097;&#108;&#108;&#101;&#115;&#103;&#117;&#116;&#101;&#046;&#108;&#105;&#118;&#101;
          </a>
          <br />
        </Content>
        <Content>
          <strong>Club Volta</strong>
          <br />
          Schanzenstr. 6-20
          <br />
          51063 Köln
          <br />
          Gebäude: 2.10
          <br />
          <br />
          <strong>Carlswerk Victoria</strong>
          <br />
          Schanzenstr. 6-20
          <br />
          51063 Köln
          <br />
          Gebäude: 3.12
        </Content>
      </Cols>
      <Partner>
        <div>
          <a href="https://www.frueh.de" target="_blank" rel="noreferrer">
            <img src={frlogo} alt="früh" />
          </a>
          <a href="https://www.4sound.tv" target="_blank" rel="noreferrer">
            <img src={s4logo} alt="Sound 4" />
          </a>
          <a href="https://bueth-software.com" target="_blank" rel="noreferrer">
            <img src={bslogo} alt="Bueth-Software.com" />
          </a>
          <a href="https://www.carlsbergdeutschland.de" target="_blank" rel="noreferrer">
            <img src={cblogo} alt="Carlsberg" />
          </a>
          <a href="https://franziskaner-weissbier.de" target="_blank" rel="noreferrer">
            <img src={fklogo} alt="Franziskaner" />
          </a>
          <a href="https://www.naturstrom.de" target="_blank" rel="noreferrer">
            <img src={nslogo} alt="naturstrom" />
          </a>
        </div>
      </Partner>
      <Imprint>
        <button ref={button} onClick={() => setPrivacyOpen((current) => !current)}>
          ©2022 AllesGute.Live GmbH - Impressum - Datenschutzerklärung
        </button>
        <div className={privacyOpen ? 'open' : undefined}>
          <Top>
            <br />
            <br />
            <h3>IMPRESSUM</h3>
            AllesGute.Live Kultur- und Betriebs GmbH
            <br />
            Schanzenstr. 6 – 20
            <br />
            51063 Köln-Mülheim
            <br />
            <br />
            Telefon: +49 (0) 221 888 906 0<br />
            E-Mail: info@allesgutelive.de
            <br />
            <br />
            Geschäftsführer
            <br />
            Daniel Czichopad
            <br />
            <br />
            AG Köln HRB 85744
            <br />
            USt-IdNr.: DE303361525
          </Top>
          <Bottom>
            <h3>DATENSCHUTZERKLÄRUNG</h3>
            Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von
            personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb unseres Onlineangebotes und der mit ihm
            verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B. unser Social Media
            Profile auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten
            Begrifflichkeiten, wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die Definitionen im
            Art. 4 der Datenschutzgrundverordnung (DSGVO).
            <br />
            <br />
            VERANTWORTLICHER
            <br />
            AllesGute.Live Kultur- und Betriebs GmbH
            <br />
            Schanzenstr. 6 – 20
            <br />
            51063 Köln-Mülheim
            <br />
            <br />
            Telefon: +49 (0) 221 888 906 0<br />
            E-Mail: info@allesgutelive.de
            <br />
            <br />
            Geschäftsführer: Daniel Czichopad
            <br />
            <br />
            ARTEN DER VERARBEITETEN DATEN:
            <br />
            Bestandsdaten (z.B., Namen, Adressen).
            <br />
            Kontaktdaten (z.B., E-Mail, Telefonnummern).
            <br />
            Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).
            <br />
            Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).
            <br />
            Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).
            <br />
            KATEGORIEN BETROFFENER PERSONEN
            <br />
            Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend
            auch als „Nutzer“).
            <br />
            <br />
            ZWECK DER VERARBEITUNG
            <br />
            Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.
            <br />
            Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.
            <br />
            Sicherheitsmaßnahmen.
            <br />
            Reichweitenmessung/Marketing
            <br />. VERWENDETE BEGRIFFLICHKEITEN
            <br />
            „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare
            natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche
            Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen,
            zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren
            besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen,
            psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.
            <br />
            <br />
            „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
            Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch
            jeden Umgang mit Daten.
            <br />
            <br />
            „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen
            Daten ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person
            zugeordnet werden können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und
            technischen und organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten
            nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.
            <br />
            <br />
            „Profiling“ jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese
            personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche
            Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche Lage,
            Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel
            dieser natürlichen Person zu analysieren oder vorherzusagen.
            <br />
            <br />
            Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen
            Daten entscheidet, bezeichnet.
            <br />
            <br />
            „Auftragsverarbeiter“ eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die
            personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
            <br />
            <br />
            MASSGEBLICHE RECHTSGRUNDLAGE
            <br />
            Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. Sofern
            die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die Rechtsgrundlage für
            die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die
            Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung
            von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer
            rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage für die Verarbeitung zur
            Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den Fall, dass lebenswichtige
            Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener
            Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
            <br />
            <br />
            SICHERHEITSMASSNAHME
            <br />
            Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des Stands der Technik, der
            Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
            unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos für die Rechte und Freiheiten
            natürlicher Personen, geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes
            Schutzniveau zu gewährleisten.
            <br />
            <br />
            Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von
            Daten durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der
            Eingabe, Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren
            eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und Reaktion auf Gefährdung
            der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der
            Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes
            durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen (Art. 25 DSGVO).
            <br />
            <br />
            ZUSAMMENARBEIT MIT AUFTRAGSVERARBEITERN UND DRITTEN
            <br />
            Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen
            (Auftragsverarbeitern oder Dritten) offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf die
            Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der
            Daten an Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung
            erforderlich ist), Sie eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage
            unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.).
            <br />
            <br />
            Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog. „Auftragsverarbeitungsvertrages“
            beauftragen, geschieht dies auf Grundlage des Art. 28 DSGVO.
            <br />
            <br />
            ÜBERMITTLUNGEN IN DRITTLÄNDER
            <br />
            Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des Europäischen
            Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder
            Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht, erfolgt dies nur, wenn es zur Erfüllung
            unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen
            Verpflichtung oder auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder
            vertraglicher Erlaubnisse, verarbeiten oder lassen wir die Daten in einem Drittland nur beim Vorliegen der
            besonderen Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf
            Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden
            Datenschutzniveaus (z.B. für die USA durch das „Privacy Shield“) oder Beachtung offiziell anerkannter
            spezieller vertraglicher Verpflichtungen (so genannte „Standardvertragsklauseln“).
            <br />
            <br />
            RECHTE DER BETROFFENEN PERSONEN
            <br />
            Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf
            Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend Art. 15 DSGVO.
            <br />
            <br />
            Sie haben entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung der Sie betreffenden Daten oder die
            Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
            <br />
            <br />
            Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende Daten unverzüglich
            gelöscht werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der
            Daten zu verlangen.
            <br />
            <br />
            Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach
            Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern.
            <br />
            <br />
            Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde
            einzureichen.
            <br />
            <br />
            WIDERRUFSRECHT
            <br />
            Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu
            widerrufen
            <br />
            <br />
            WIDERSPRUCHSRECHT
            <br />
            Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit
            widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung
            erfolgen.
            <br />
            <br />
            COOKIES UND WIDERSPRUCHSRECHT BEI DIREKTWERBUNG
            <br />
            Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb
            der Cookies können unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu
            einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder auch nach seinem Besuch
            innerhalb eines Onlineangebotes zu speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente
            Cookies“, werden Cookies bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot verlässt und
            seinen Browser schließt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop
            oder ein Login-Status gespeichert werden. Als „permanent“ oder „persistent“ werden Cookies bezeichnet, die
            auch nach dem Schließen des Browsers gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden,
            wenn die Nutzer diese nach mehreren Tagen aufsuchen. Ebenso können in einem solchen Cookie die Interessen
            der Nutzer gespeichert werden, die für Reichweitenmessung oder Marketingzwecke verwendet werden. Als
            „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen, der das
            Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur dessen Cookies sind spricht man von
            „First-Party Cookies“).
            <br />
            <br />
            Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen unserer
            Datenschutzerklärung auf.
            <br />
            <br />
            Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die
            entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies können
            in den Systemeinstellungen des Browsers gelöscht werden. Der Ausschluss von Cookies kann zu
            Funktionseinschränkungen dieses Onlineangebotes führen.
            <br />
            <br />
            Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann
            bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, über die US-amerikanische Seite
            http://www.aboutads.info/choices/ oder die EU-Seite http://www.youronlinechoices.com/ erklärt werden. Des
            Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers
            erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes
            genutzt werden können.
            <br />
            <br />
            LÖSCHUNG VON DATEN
            <br />
            Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in ihrer
            Verarbeitung eingeschränkt. Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben,
            werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr erforderlich
            sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten nicht
            gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind, wird deren
            Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt
            z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen.
            <br />
            <br />
            Nach gesetzlichen Vorgaben in Deutschland, erfolgt die Aufbewahrung insbesondere für 10 Jahre gemäß §§ 147
            Abs. 1 AO, 257 Abs. 1 Nr. 1 und 4, Abs. 4 HGB (Bücher, Aufzeichnungen, Lageberichte, Buchungsbelege,
            Handelsbücher, für Besteuerung relevanter Unterlagen, etc.) und 6 Jahre gemäß § 257 Abs. 1 Nr. 2 und 3, Abs.
            4 HGB (Handelsbriefe).
            <br />
            <br />
            Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung insbesondere für 7 J gemäß § 132 Abs. 1
            BAO (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege, Geschäftspapiere, Aufstellung der Einnahmen
            und Ausgaben, etc.), für 22 Jahre im Zusammenhang mit Grundstücken und für 10 Jahre bei Unterlagen im
            Zusammenhang mit elektronisch erbrachten Leistungen, Telekommunikations-, Rundfunk- und Fernsehleistungen,
            die an Nichtunternehmer in EU-Mitgliedstaaten erbracht werden und für die der Mini-One-Stop-Shop (MOSS) in
            Anspruch genommen wird.
            <br />
            <br />
            KONTAKTAUFNAHME
            <br />
            Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via sozialer Medien) werden
            die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b.
            (im Rahmen vertraglicher-/vorvertraglicher Beziehungen), Art. 6 Abs. 1 lit. f. (andere Anfragen) DSGVO
            verarbeitet.. Die Angaben der Nutzer können in einem Customer-Relationship-Management System ("CRM System")
            oder vergleichbarer Anfragenorganisation gespeichert werden.
            <br />
            <br />
            Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir überprüfen die Erforderlichkeit
            alle zwei Jahre; Ferner gelten die gesetzlichen Archivierungspflichten.
            <br />
            <br />
            HOSTING UND E-MAIL-VERSAND
            <br />
            Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden
            Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
            Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum
            Zwecke des Betriebs dieses Onlineangebotes einsetzen.
            <br />
            <br />
            Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten,
            Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
            Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren
            Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
            Auftragsverarbeitungsvertrag).
            <br />
            <br />
            ERHEBUNG VON ZUGRIFFSDATEN UND LOGFILES
            <br />
            Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6
            Abs. 1 lit. f. DSGVO Daten über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet
            (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen Webseite, Datei, Datum und
            Uhrzeit des Abrufs, übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das
            Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider.
            <br />
            <br />
            Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder
            Betrugshandlungen) für die Dauer von maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren weitere
            Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von
            der Löschung ausgenommen.
            <br />
            <br />
            GOOGLE ANALYTICS
            <br />
            Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und
            wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Google Analytics,
            einen Webanalysedienst der Google LLC („Google“) ein. Google verwendet Cookies. Die durch das Cookie
            erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen
            Server von Google in den USA übertragen und dort gespeichert.
            <br />
            <br />
            Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das
            europäische Datenschutzrecht einzuhalten
            (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).
            <br />
            <br />
            Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch
            die Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen
            und um weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen,
            uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer
            erstellt werden.
            <br />
            <br />
            Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der
            Nutzer wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
            des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse
            an einen Server von Google in den USA übertragen und dort gekürzt.
            <br />
            <br />
            Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google
            zusammengeführt. Die Nutzer können die Speicherung der Cookies durch eine entsprechende Einstellung ihrer
            Browser-Software verhindern; die Nutzer können darüber hinaus die Erfassung der durch das Cookie erzeugten
            und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch
            Google verhindern, indem sie das unter folgendem Link verfügbare Browser-Plugin herunterladen und
            installieren: http://tools.google.com/dlpage/gaoptout?hl=de.
            <br />
            <br />
            Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten, erfahren
            Sie in der Datenschutzerklärung von Google (https://policies.google.com/technologies/ads) sowie in den
            Einstellungen für die Darstellung von Werbeeinblendungen durch Google
            (https://adssettings.google.com/authenticated).
            <br />
            <br />
            Die personenbezogenen Daten der Nutzer werden nach 14 Monaten gelöscht oder anonymisiert.
            <br />
            <br />
            ONLINEPRÄSENZEN IN SOZIALEN MEDIEN
            <br />
            Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort aktiven
            Kunden, Interessenten und Nutzern kommunizieren und sie dort über unsere Leistungen informieren zu können.
            Beim Aufruf der jeweiligen Netzwerke und Plattformen gelten die Geschäftsbedingungen und die
            Datenverarbeitungsrichtlinien deren jeweiligen Betreiber.
            <br />
            <br />
            Soweit nicht anders im Rahmen unserer Datenschutzerklärung angegeben, verarbeiten wir die Daten der Nutzer
            sofern diese mit uns innerhalb der sozialen Netzwerke und Plattformen kommunizieren, z.B. Beiträge auf
            unseren Onlinepräsenzen verfassen oder uns Nachrichten zusenden.
            <br />
            <br />
            EINBINDUNG VON DIENSTEN UND INHALTEN DRITTER
            <br />
            Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten Interessen (d.h. Interesse
            an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1
            lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um deren Inhalte und Services, wie z.B.
            Videos oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als “Inhalte”).
            <br />
            <br />
            Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie
            ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die
            Darstellung dieser Inhalte erforderlich. Wir bemühen uns nur solche Inhalte zu verwenden, deren jeweilige
            Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner so
            genannte Pixel-Tags (unsichtbare Grafiken, auch als "Web Beacons" bezeichnet) für statistische oder
            Marketingzwecke verwenden. Durch die "Pixel-Tags" können Informationen, wie der Besucherverkehr auf den
            Seiten dieser Website ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem
            Gerät der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und
            Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes
            enthalten, als auch mit solchen Informationen aus anderen Quellen verbunden werden.
            <br />
            <br />
            GOOGLE FONTS
            <br />
            Wir binden die Schriftarten ("Google Fonts") des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain
            View, CA 94043, USA, ein. Datenschutzerklärung: https://www.google.com/policies/privacy/, Opt-Out:
            https://adssettings.google.com/authenticated.
            <br />
            <br />
            Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke
          </Bottom>
        </div>
      </Imprint>
    </Wrapper>
  )
}
