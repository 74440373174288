import React, { useEffect, useState } from 'react'
import './App.css'

import { firestore } from '../../lib/firebase'
import useAuth from '../../customHooks/useAuth'
import useMenu from '../../customHooks/useMenu'

import NoRoles from './Content/NoRoles'
import Dashboard from './Content/Dashboard'
import Events from './Content/Events'
import Organizers from './Content/Organizers'
import News from './Content/News'
import Faqs from './Content/Faqs'
import Users from './Content/Users'
// import Settings from './Content/Settings'
import Surface from './Surface'

export default function Admin({ location }) {
  const { pathname } = location
  const { currentUser } = useAuth()
  const { loading, setLoading } = useMenu()
  const [roles, setRoles] = useState([])
  const { db, collection, getDocs, addDoc, query, where, limit } = firestore

  useEffect(() => {
    async function fetchData() {
      const loadedRoles = await getRoles()
      setRoles(loadedRoles)
      setLoading(false)
    }
    setLoading(true)
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getRoles = async () => {
    // console.log('Admin', 'getRoles')
    let roles = null
    const q = query(collection(db, 'users'), where('email', '==', currentUser.email), limit(1))
    await getDocs(q).then((querySnapshot) =>
      querySnapshot.forEach((doc) => {
        roles = doc.data().roles
      }),
    )

    if (!roles) {
      if (currentUser.emailVerified) {
        await addDoc(collection(db, 'users'), {
          signupDate: new Date().toISOString(),
          displayName: currentUser.displayName,
          email: currentUser.email,
          roles: [],
        })
      } else {
        alert('Fehler: der User konnte nicht angelegt werden, weil die Email Adresse nicht verifiziert ist.')
      }
    }
    return roles || []
  }

  return (
    <Surface roles={roles}>
      {!loading && (
        <>
          {roles.length === 0 && <NoRoles />}
          {roles.includes('dashboard') && pathname === '/admin/dashboard' && <Dashboard />}
          {roles.includes('news') && pathname === '/admin/news' && <News />}
          {roles.includes('events') && pathname === '/admin/events' && <Events />}
          {roles.includes('organizers') && pathname === '/admin/organizers' && <Organizers />}
          {roles.includes('faqs') && pathname === '/admin/faqs' && <Faqs />}
          {roles.includes('users') && pathname === '/admin/users' && <Users />}
          {/* roles.includes('settings') && pathname === '/admin/settings' && <Settings /> */}
        </>
      )}
    </Surface>
  )
}
