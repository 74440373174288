import styled from '@emotion/styled'
import { mq } from '../../../../lib/theme'

const Styling = styled.div`
  height: 99%;
  display: grid;
  grid-template-columns: 296px 1fr;
  grid-gap: ${({ theme }) => theme.dimensions.distance.mobile};

  ${mq(breakpoints => breakpoints.desktop)} {
    grid-gap: ${({ theme }) => theme.dimensions.distance.desktop};
  }
`

Styling.Card = styled.div`
  overflow: auto;
`

Styling.EventCard = styled.div`
  overflow: auto;
  box-shadow: ${({ theme }) => theme.colors.shadowCard};
  background: ${({ theme }) => theme.colors.foreground};
  border-radius: 5px;
  min-width: 500px;
  padding: 10px;
`

Styling.Label = styled.div`
  text-align: left;
  font-size: ${({ theme }) => theme.font.size.menu.mobile};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  padding: 12px 5px 12px 5px;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #F1F2F7;
`

Styling.EventList = styled.div`
  display: flex;
  flex-direction: column;
    margin-bottom: 10px;
`

Styling.EventListEntry = styled.button`
  all: unset;
  outline: none;
  display: block;
  padding: 10px;
  margin-right: 10px;
  border: ${({ theme }) => `1px solid ${theme.colors.menuActive}`};
  font-weight: ${({ theme, active }) => theme.font.weight[(active ? 'semibold' : 'light')]};
  font-size: ${({ theme }) => theme.font.size.menu.mobile};
  background: ${({ theme, active }) => (active ? theme.colors.itemHover : theme.colors.menuNorm)};
  transition: ${({ theme }) => theme.transition.standard};
  color: ${({ theme, active }) => theme.colors[active ? 'itemActive' : 'iconNorm']};
  overflow: hidden;
  max-height: 45px;

  &:last-of-type {
    border-bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  &:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  & > svg {
    margin: -3px 8px 0 0;
    height: 20px;
    width: 27px;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.itemHover};
  }
`

Styling.Images = styled.div`
  margin-top: 8px;
  height: 100%;
  padding-bottom: 16px;
`

Styling.Image = styled.div`
  background-image: ${({ src }) => `url("${src}")`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: ${({ horizontal }) => `${horizontal || 'center'} center`};
  width: 100%;
  height: 100%;
  display: inline-block;
  margin: 0 10px 10px 0;
  border-radius: 5px;
  border: ${({ theme }) => `1px solid ${theme.colors.menuActive}`};
  padding: 10px;
  text-align: right;
`

Styling.Button = styled.button`
  all: unset;
  display: flex;
  outline: none;
  align-items: center;
  width: calc(100% - 10px);
  height: ${({ theme }) => theme.dimensions.menuButtonHeight.mobile};
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.menuActive}`};
  font-weight: ${({ theme, active }) => theme.font.weight[(active ? 'semibold' : 'light')]};
  font-size: ${({ theme }) => theme.font.size.menu.mobile};
  background: ${({ theme, active }) => (active ? theme.colors.menuActive : theme.colors.menuNorm)};
  transition: ${({ theme }) => theme.transition.standard};
  color: ${({ theme, active }) => theme.colors[active ? 'iconActive' : 'iconNorm']};

  &:hover {
    background: ${({ theme }) => theme.colors.menuHover};
  }
  & > svg {
    margin: 0 15px;
    width: ${({ theme }) => theme.dimensions.menuIcon.mobile} !important;
    height: ${({ theme }) => theme.dimensions.menuIcon.mobile} !important;
  }
  & > div {
    color: ${({ theme }) => theme.colors.default};
  } 

`

export default Styling
