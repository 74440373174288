import styled from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  max-width: 100%;
  background: white;
  z-index: 100;
  border: none;
  font-size: 22px;
  transition: box-shadow 0.3s linear;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 15%);

  @media (min-width: 768px) {
    box-shadow: ${(props) => ( props['data-scrolltop'] > 100 ? '0 5px 10px 0 rgb(0 0 0 / 15%)' : 'none')};
  }
`

export const MainGrid = styled.div`
  max-width: 1000px;
  height: 80px;
  margin: auto;
  display: grid;
  align-content: center;
  grid-template-columns: 15px 35px 160px 1fr 15px;  

  @media (min-width: 768px) {
    grid-template-columns: 20px 1fr 20px 3fr 20px 2fr 300px 3fr 20px 2fr 20px 1fr 20px;  
  }

  @media (min-width: 1060px) {
    // grid-template-columns: 0px 3fr 30px 3fr 240px 2fr 30px 2fr 30px 2fr 0px;  
    grid-template-columns: 0px 1fr 30px 3fr 30px 2fr 300px 3fr 30px 2fr 30px 1fr 0px;  
    // search programm info anfahrt faq contact
  }

  & > a > svg {
    width: 35px;
    height: 35px;
  }
`

export const NewsGrid = styled.div`
  height: 80px;
  margin: auto;
  display: grid;
  align-content: center;
  grid-template-columns: 15px 35px 1fr 35px 15px;

  & > a > svg {
    width: 35px;
    height: 35px;
  }
`

export const Link = styled(RouterLink)`
  display: none;
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  font-size: 17px;
  font-weight: 700;

  @media (min-width: 768px) {
    display: block;
  }
`

export const MobileLink = styled(RouterLink)`
  text-transform: uppercase;
  color: black;
  text-decoration: none;
  font-size: 28px;
  display: block;
  text-align: center;
  margin-bottom: 15px;
`

export const IconLink = styled(RouterLink)`
  display: block;
  color: black;
  text-decoration: none;
`

export const IconButton = styled.button`
  border: 0;
  background: transparent;
  display: block;
  color: black;
  text-decoration: none;
  display: block;

  @media (min-width: 768px) {
    display: none;
  }

  & > svg {
    width: 35px;
    height: 35px;
  }
`

export const MobileMenu = styled.nav`
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  left: 0;
  right: 0;
  top: 78px;
  padding: 0 15px;
  transition: .3s background-color ease-in;
  bottom: 100vh;
  z-index: 100;

  &.show {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
  }

  @media (min-width: 768px) {
    display: none;
  }
`

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
  max-width: 290px;
  height: 0;
  overflow: hidden;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border: 1px outset #CCC;
  padding: 0 15px;
  transition: 0.3s all ease-in-out;
  margin: auto;

  &.show {
    height: 430px;
    padding: 80px 15px 15px 15px;

    @media (min-width: 640px) {
      height: 500px;
      padding: 140px 15px 35px 15px;
    }
  }

  @media (min-width: 768px) {
    display: none;
  }
`

export default Wrapper
