import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { Button, ButtonGroup, Container, Col, Form, InputGroup, Row } from 'react-bootstrap'

import {
  IoEarth as IconInternal,
  IoBus as IconTour,
  IoPerson as IconOrganizer,
  IoTicketSharp as IconTickets,
  IoLogoFacebook as IconFacebook,
  IoTimeOutline as IconAdmission,
  IoTime as IconBegin,
  IoEye as IconOnline,
} from 'react-icons/io5'

import { AiOutlineEuroCircle as PriceVvk, AiFillEuroCircle as PriceAk } from 'react-icons/ai'

import {
  BsExclamationLg as IconExtra,
  BsCalendarEvent as IconCalendar,
  BsArchiveFill as IconEvent,
  BsAsterisk as PreviousIcon,
} from 'react-icons/bs'

import Logo from '../../../shared/Logo'
import Styling from './Styling'
import Act from './Act'
import EventPicture from './EventPicture'

import colors from '../../../../lib/colors'

function Event({
  eventId,
  label,
  setLabel,
  date,
  setDate,
  volta,
  setVolta,
  garden,
  setGarden,
  victoria,
  setVictoria,
  publish,
  online,
  ready,
  extra,
  setExtra,
  extraDate,
  setExtraDate,
  tour,
  setTour,
  admission,
  setAdmission,
  organizers,
  organizerName,
  setOrganizerName,
  setOrganizerUrl,
  begin,
  setBegin,
  eventFacebook,
  setEventFacebook,
  prePrice,
  setPrePrice,
  tickets,
  setTickets,
  price,
  setPrice,
  htmlContent,
  setHtmlContent,
  acts,
  upload,
  delImage,
  editAct,
  deleteAct,
  moveAct,
  mergeActs,
  save,
  previousId,
  setPreviousId,
  previousSlug,
  setPreviousSlug,
}) {
  const hasEventPicture = !!acts.find((a) => a.type === 'eventpicture')

  return !eventId ? null : (
    <Styling.EventCard>
      <Container style={{ height: 'calc(100vh - 318px)', overflow: 'auto' }} className="my-2">
        <Row>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconEvent />
                &nbsp;Label *
              </InputGroup.Text>
              <Form.Control placeholder="" value={label} onChange={(e) => setLabel(e.target.value)} />
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconCalendar />
                &nbsp;Datum *
              </InputGroup.Text>
              <Form.Control
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                min={new Date().toISOString().substring(0, 10)}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonGroup className="my-2 w-100">
              <Button variant={`${volta ? '' : 'outline-'}dark`} onClick={() => setVolta((current) => !current)}>
                Club Volta
              </Button>
              <Button variant={`${garden ? '' : 'outline-'}dark`} onClick={() => setGarden((current) => !current)}>
                Biergarten
              </Button>
              <Button variant={`${victoria ? '' : 'outline-'}dark`} onClick={() => setVictoria((current) => !current)}>
                Carlswerk Victoria
              </Button>
            </ButtonGroup>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconOnline />
                &nbsp;Event
              </InputGroup.Text>
              <div className="px-3" style={{ marginTop: '-8px' }}>
                <Form.Check
                  type="switch"
                  label="Online anzeigen"
                  onChange={(e) => publish(e.target.checked)}
                  checked={online}
                  disabled={!ready}
                  className="mt-3"
                />
              </div>
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconExtra />
                &nbsp;Achtung
              </InputGroup.Text>
              <Form.Select
                value={extra}
                style={{ color: colors[extra] }}
                onChange={(e) => setExtra(e.target.options[e.target.selectedIndex].value)}
              >
                <option value=""></option>
                <option value="abgesagt">abgesagt</option>
                <option value="ausverkauft">ausverkauft</option>
                <option value="Nachholtermin">Nachholtermin</option>
                <option value="neu bestätigt">neu bestätigt</option>
                <option value="verlegt">verlegt</option>
                <option value="verschoben">verschoben</option>
              </Form.Select>
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                {' '}
                <IconExtra />
                &nbsp;anzeigen&nbsp;bis
              </InputGroup.Text>
              <Form.Control
                type="date"
                value={extraDate}
                onChange={(e) => setExtraDate(e.target.value)}
                min={new Date().toISOString().substring(0, 10)}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconTour />
                &nbsp;Tour
              </InputGroup.Text>
              <Form.Control placeholder="" value={tour} onChange={(e) => setTour(e.target.value)} />
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconAdmission />
                &nbsp;Einlass
              </InputGroup.Text>
              <Form.Control type="time" value={admission} onChange={(e) => setAdmission(e.target.value)} />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconOrganizer />
                &nbsp;Veranstalter
              </InputGroup.Text>

              <Form.Select
                value={organizerName}
                onChange={(e) => {
                  const name = e.target.options[e.target.selectedIndex].value
                  const organizer = organizers.find((o) => o.name === name)
                  setOrganizerName(name)
                  setOrganizerUrl(organizer?.website ?? '')
                }}
              >
                <option value="">Bitte auswählen</option>
                {organizers.map(({ name }) => (
                  <option value={name} key={name}>
                    {name}
                  </option>
                ))}
              </Form.Select>
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconBegin />
                &nbsp;Beginn
              </InputGroup.Text>
              <Form.Control type="time" value={begin} onChange={(e) => setBegin(e.target.value)} />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconFacebook />
                &nbsp;facebook
              </InputGroup.Text>
              <Form.Control
                placeholder="https://"
                value={eventFacebook}
                onChange={(e) => setEventFacebook(e.target.value)}
              />
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <PriceVvk />
                &nbsp;Vorverkauf
              </InputGroup.Text>
              <Form.Control placeholder="" value={prePrice} onChange={(e) => setPrePrice(e.target.value)} />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconTickets />
                &nbsp;Tickets
              </InputGroup.Text>
              <Form.Control placeholder="https://" value={tickets} onChange={(e) => setTickets(e.target.value)} />
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <PriceAk />
                &nbsp;Abendkasse
              </InputGroup.Text>
              <Form.Control placeholder="" value={price} onChange={(e) => setPrice(e.target.value)} />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <PreviousIcon />
                &nbsp;Previous ID
              </InputGroup.Text>
              <Form.Control placeholder="" value={previousId} onChange={(e) => setPreviousId(e.target.value)} />
            </InputGroup>
          </Col>
          <Col xs={6}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <PreviousIcon />
                &nbsp;PreviousSlug
              </InputGroup.Text>
              <Form.Control placeholder="" value={previousSlug} onChange={(e) => setPreviousSlug(e.target.value)} />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col className="my-2">
            <ReactQuill theme="snow" value={htmlContent} onChange={setHtmlContent} />
          </Col>
        </Row>

        {acts && acts.length > 0 && (
          <>
            {!hasEventPicture && (
              <ButtonGroup className="my-2 w-100">
                <Button variant="primary" onClick={() => mergeActs({ type: 'eventpicture', sort: -1 }, true)}>
                  Extra Eventbild anlegen
                </Button>
              </ButtonGroup>
            )}
            {acts
              .sort((a, b) => a.type.localeCompare(b.type))
              .map((act, actindex) => (
                <React.Fragment key={`act-${actindex}`}>
                  {act.type === 'eventpicture' ? (
                    <EventPicture
                      pic={act}
                      actindex={actindex}
                      upload={upload}
                      delImage={delImage}
                      deleteAct={deleteAct}
                    />
                  ) : (
                    <Act
                      act={act}
                      acts={acts}
                      actindex={actindex}
                      upload={upload}
                      delImage={delImage}
                      editAct={editAct}
                      deleteAct={deleteAct}
                      moveAct={moveAct}
                    />
                  )}
                </React.Fragment>
              ))}
          </>
        )}
      </Container>
      <Container>
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col xs={9}>
            <InputGroup className="my-2">
              <InputGroup.Text style={{ width: '150px' }}>
                <IconInternal />
                &nbsp;
                <a target="_blank" rel="noreferrer" href={`${window.location.origin}/event/${eventId}`}>
                  Preview
                </a>
              </InputGroup.Text>
              <Form.Control value={`/event/${eventId}`} onChange={() => {}} disabled />
            </InputGroup>
          </Col>
          <Col xs={3}>
            <Button variant={!ready ? 'danger' : 'success'} onClick={save} className="my-2 w-100" disabled={!ready}>
              <Logo volta={volta} garden={garden} victoria={victoria} color="#FFFFFF" width={27} height={22} /> Save
            </Button>
          </Col>
        </Row>
      </Container>
    </Styling.EventCard>
  )
}

export default Event
