import { useEffect, useMemo, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import Wrapper, { Inner, InnerImage, Image, ExtraContent, TileContent } from './Styling'

import getExtra from '../../../lib/getExtra'
import { firestore } from '../../../lib/firebase'

const Diamonds = () => {
  const numNews = 3
  const numUpdates = 13
  const location = useLocation()
  const { db, collection, getDocs, query, where, orderBy, limit } = firestore
  const [news, setNews] = useState(undefined)
  const [updates, setUpdates] = useState(undefined)
  const [reloadTimer, setReloadTimer] = useState(undefined)
  const [init, setInit] = useState(true)

  const [yesterday, tomorrow] = useMemo(() => {
    const today = new Date(new Date().toISOString().slice(0, 10))
    return [
      new Date(new Date(today.setDate(today.getDate() - 1)).toISOString().slice(0, 10)),
      new Date(new Date(today.setDate(today.getDate() + 2)).toISOString().slice(0, 10)),
    ]
  }, [])

  const validate = (events) => {
    const valid = events.filter((e) => e.acts)
    valid.map((event) => {
      const ep = event.acts.filter(({ type }) => type === 'eventpicture')
      const ma = event.acts.filter(({ type }) => type === 'main')
      event.mainacts = ma.length > 0 ? ma : event.acts
      event.specials = event.acts.filter(({ type }) => type === 'special')
      event.supports = event.acts.filter(({ type }) => type === 'support')
      event.image = ep?.[0]?.images?.[0]?.src || event.mainacts[0]?.images?.[0]?.src || ''
      event.main = event.mainacts.reduce((acc, act) => [...acc, act.name], []).join(', ')
      event.special = event.specials.reduce((acc, act) => [...acc, act.name], []).join(', ')
      event.support = event.supports.reduce((acc, act) => [...acc, act.name], []).join(', ')
      return event
    })

    return valid
  }

  const validateNews = (news) => {
    const valid = news
    valid.map((item) => {
      item.image = item?.images?.[0]?.src || ''
      item.main = item.label
      return item
    })

    return valid
  }

  const getNews = useCallback(() => {
    const newsQuery = query(collection(db, 'news'), where('online', '==', true), orderBy('lastUpdate', 'desc'))
    getDocs(newsQuery)
      .then((querySnapshot) => {
        const res = []
        querySnapshot.forEach((doc) => {
          const data = {
            date: '', // immer leer initialisieren
            begin: '',
            news: true,
            ...doc.data(),
          }
          res.push({ id: doc.id, ...data })
        })
        return validateNews(res)
      })
      .then((res) =>
        setNews(
          res
            .filter((r) => {
              if (!r?.dateEnd) {
                return new Date(r.date) < tomorrow
              }
              return new Date(r.date) < tomorrow && new Date(r?.dateEnd) > yesterday
            })
            .slice(0, numNews),
        ),
      )
  }, [collection, db, getDocs, orderBy, query, tomorrow, where, yesterday])

  const getUpdates = useCallback(() => {
    const newsQuery = query(
      collection(db, 'events'),
      // where('date', '>=', new Date().toISOString().slice(0, 10)),
      where('online', '==', true),
      // orderBy('date', 'desc'),
      orderBy('lastUpdate', 'desc'),
      limit(20),
    )

    getDocs(newsQuery)
      .then((querySnapshot) => {
        const res = []
        querySnapshot.forEach((doc) => {
          const data = {
            date: '', // immer leer initialisieren
            begin: '',
            ...doc.data(),
          }
          res.push({ id: doc.id, ...data })
        })
        return validate(res)
      })
      .then((res) => {
        setUpdates(res.filter((r) => Date.parse(r.date) - Date.parse(new Date()) + 86400000 > 0).slice(0, numUpdates))
      })
  }, [collection, db, getDocs, limit, orderBy, query, where])

  useEffect(() => {
    getNews()
    getUpdates()
    setReloadTimer(
      window.setTimeout(() => {
        window.location.reload()
      }, 2000),
    )
  }, [getNews, getUpdates])

  useEffect(() => {
    if (reloadTimer && updates) {
      clearTimeout(reloadTimer)
    }
  }, [updates, reloadTimer])

  useEffect(() => {
    window.setTimeout(() => setInit(false), 2000)
  }, [])

  const data = useMemo(() => [...(news ?? []), ...(updates ?? [])].slice(0, numUpdates), [news, updates])

  const animation = useMemo(
    () => (init && location.pathname.endsWith('news') ? 'true' : undefined),
    [init, location.pathname],
  )

  return (
    <Wrapper data-animation={animation}>
      <Inner>
        {data.map(({ id, image, extra, extraDate, label }) => (
          <Image to={`/event/${id}`} key={id}>
            <InnerImage style={{ backgroundImage: `url(${image})` }}>
              <ExtraContent data-extra={getExtra(extra, extraDate)}>{getExtra(extra, extraDate)}</ExtraContent>
              <TileContent>{label.length > 24 ? `${label.substr(0, 22)}...` : label}</TileContent>
            </InnerImage>
          </Image>
        ))}
      </Inner>
    </Wrapper>
  )
}

export default Diamonds
