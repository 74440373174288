import styled from 'styled-components'

export const ClubArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 8px;
`

export const ClubName = styled.div`
  font-size: 16px;
  margin-left: 8px;
`

export const Anfahrt = styled.div`
  & > p {
    font-size: 18px;
    &:nth-of-type(1) {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
    }
    &:nth-of-type(2) {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
    &:nth-of-type(3) {
      display: none;
      grid-column: 2 / 3;
      grid-row: 1 / 2;
    }
    &:nth-of-type(4) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
    }
  }
  @media (min-width:640px) {
    margin-top: 15px;
  }
  @media (min-width:1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    row-gap: 0;
    margin-top: 30px;
    grid-row: 1 / 2;
    grid-column: 1 / 3;
  }
`

export const MapVolta = styled.div`
  margin-top: 30px;

  & iframe {
    border-radius: 15px;
    max-height: calc(100vh - 200px);
  }

  @media (min-width:1000px) {
    margin-top: 0;
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }
`

export const MapVictoria = styled.div`
  margin-top: 30px;

  & iframe {
    border-radius: 15px;
    max-height: calc(100vh - 200px);
  }

  @media (min-width:1000px) {
    margin-top: 0;
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
`

const Wrapper = styled.div`
  margin: auto;
  max-width: 1000px;

  @media (min-width:1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
`

export default Wrapper
