import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'

// import getEventDate from '../../../lib/getEventDate'

import Club from '../../shared/Club'
import { firestore } from '../../../lib/firebase'
import Wrapper, { Container, Image, Grid, EventName, Description, DateClub, ClubArea } from './Styling'

const News = ({ label, date, dateEnd, htmlContent, volta, garden, victoria, images }) => {
  const image = images?.[0]?.src || ''

  return (
    <>
      <Image src={image} alt={label}>
        <div />
      </Image>
      <Grid>
        <div>
          <EventName>{label}</EventName>
          <Description>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </Description>
        </div>

        <div>
          <DateClub>
            {/* <EventDate>{getEventDate(date)}</EventDate> */}
            {/* dateEnd && date !== dateEnd && (
              <EventDate>
                bis
                <br />
                {getEventDate(dateEnd)}
              </EventDate>
            ) */}
            <ClubArea>
              <Club volta={volta} garden={garden} victoria={victoria} />
            </ClubArea>
          </DateClub>
        </div>
      </Grid>
    </>
  )
}

const NewsModal = () => {
  const { slug } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(undefined)
  const { db, getDoc, doc, collection, getDocs, query, where, limit } = firestore
  const [entered, setEntered] = useState(false)

  useEffect(() => {
    getDoc(doc(db, 'news', slug))
      .then((snapshot) => {
        setData(snapshot.data())
        setLoading(false)
        document.body.style.overflow = 'hidden'
      })
      .catch(() => history.push('/news'))
  }, [collection, db, doc, getDoc, getDocs, history, limit, query, slug, where])

  return !data ? null : (
    <Wrapper
      onClick={() => {
        if (entered) {
          return
        }
        document.body.style.overflow = 'auto'
        history.push('/news')
      }}
    >
      <Container
        className={loading ? undefined : 'show'}
        onMouseEnter={() => setEntered(true)}
        onMouseLeave={() => setEntered(false)}
      >
        <News {...data} />
      </Container>
    </Wrapper>
  )
}

export default NewsModal
