import styled from 'styled-components'

const Wrapper = styled.div`
  margin: auto;
  max-width: 1000px;
  outline: none;

  & > div > section > section.faq-row {
    border-bottom: 2px solid #000;
    &:last-of-type {
      border: 0;
    }

    & > div > div {
      margin: 0 !important;
      padding: 5px 0 !important;
    }
  }
`

export default Wrapper
