import React, { useMemo } from 'react'
import { Button } from 'react-bootstrap'
import getEventDate from '../../../lib/getEventDate'
import Club from '../../shared/Club'
import Extra from '../../shared/Extra'

import getExtra from '../../../lib/getExtra'

import Event, {
  Center,
  Image,
  EventDate,
  ExtraInfo,
  Content,
  Label,
  TourName,
  ArtistSupport,
  Bottom,
  ClubName,
  TicketsLink,
} from './Styling'

const ProgramSection = ({ events, getEvents }) => {
  const sorted = useMemo(() => {
    if (!events) {
      return null
    }
    const valid = events.filter((e) => e.acts)
    valid.map((event) => {
      const ep = event.acts.filter(({ type }) => type === 'eventpicture')
      event.specials = event.acts.filter(({ type }) => type === 'special')
      event.supports = event.acts.filter(({ type }) => type === 'support')
      event.image = ep?.[0]?.images?.[0]?.src || event.mainacts[0]?.images?.[0]?.src || ''
      event.special = event.specials.reduce((acc, act) => [...acc, act.name], []).join(', ')
      event.support = event.supports.reduce((acc, act) => [...acc, act.name], []).join(', ')
      return event
    })

    return valid.sort((a, b) =>
      a.date === b.date ? (a?.begin || '').localeCompare(b?.begin || '') : a.date.localeCompare(b.date),
    )
  }, [events])

  return !sorted ? (
    <Center>Es wird geladen...</Center>
  ) : (
    <>
      {sorted.length === 0 ? (
        <Center>
          <Button
            onClick={() => getEvents()}
            variant="outline-secondary"
            style={{ width: '100%', maxWidth: '600px', margin: '32px auto', display: 'block' }}
          >
            Klicke hier um das Programm zu laden
          </Button>
        </Center>
      ) : (
        <>
          {sorted.map(
            ({
              id,
              date,
              tour,
              extra,
              tickets,
              extraDate,
              volta,
              garden,
              victoria,
              label,
              image,
              special,
              support,
            }) => (
              <Event key={id}>
                <EventDate>{getEventDate(date)}</EventDate>
                <Image to={`/event/${id}`}>
                  <div style={{ backgroundImage: `url(${image})`, backgroundColor: '#000' }} />
                </Image>
                <Content>
                  <ExtraInfo>
                    <Extra extra={getExtra(extra, extraDate)} />
                  </ExtraInfo>
                  <Label to={`/event/${id}`}>{label}</Label>
                  {tour && <TourName>{tour}</TourName>}
                  {special && (
                    <ArtistSupport>
                      <strong>Special Guest{special.includes(', ') ? 's' : ''}:</strong> {special}
                    </ArtistSupport>
                  )}
                  {support && (
                    <ArtistSupport>
                      <strong>Support{support.includes(', ') ? 's' : ''}:</strong> {support}
                    </ArtistSupport>
                  )}
                  <Bottom>
                    <ClubName>
                      <Club volta={volta} garden={garden} victoria={victoria} />
                    </ClubName>

                    {tickets && (
                      <TicketsLink>
                        <a href={tickets} target="_blank" rel="noopener noreferrer">
                          Tickets
                        </a>
                      </TicketsLink>
                    )}
                  </Bottom>
                </Content>
              </Event>
            ),
          )}
        </>
      )}
    </>
  )
}

export default ProgramSection
