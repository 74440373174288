import styled from '@emotion/styled'
import { mq } from '../../../../lib/theme'

const Styling = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.dimensions.headerHeight.mobile};
  left: 0;
  right: 0;
  height: 3px;
  overflow: hidden;
  transition: ${({ theme }) => theme.transition.standard};

  ${mq(breakpoints => breakpoints.tablet)} {
    top: ${({ theme }) => theme.dimensions.headerHeight.tablet};
  }

  & > div {
    width: 12.5%;
    height: 3px;
    background: ${({ theme }) => theme.colors.gradientLoading};
    position: absolute;

    &:nth-of-type(1) { left: 0; animation: first 2s linear infinite; }
    &:nth-of-type(2) { left: 12.5%; animation: second 2s linear infinite; }
    &:nth-of-type(3) { left: 25%; animation: third 2s linear infinite; }
    &:nth-of-type(4) { left: 37.5%; animation: fourth 2s linear infinite; }
    &:nth-of-type(5) { left: 50%; animation: fifth 2s linear infinite; }
    &:nth-of-type(6) { left: 62.5%; animation: sixth 2s linear infinite; }
    &:nth-of-type(7) { left: 75%; animation: seventh 2s linear infinite; }
    &:nth-of-type(8) { left: 87.5%; animation: eighth 2s linear infinite; }
    &:nth-of-type(9) { left: 100%; animation: nineth 2s linear infinite; }
  }

  @keyframes first { from { left: 0; } to { left: 12.5% ;} }
  @keyframes second { from { left: 12.5%; } to { left: 25% ;} }
  @keyframes third { from { left: 25%; } to { left: 37.5% ;} }
  @keyframes fourth { from { left: 37.5%; } to { left: 50% ;} }
  @keyframes fifth { from { left: 50%; } to { left: 62.5% ;} }
  @keyframes sixth { from { left: 62.5%; } to { left: 75% ;} }
  @keyframes seventh { from { left: 75%; } to { left: 87.5% ;} }
  @keyframes eighth { from { left: 87.5%; } to { left: 100% ;} }
  @keyframes nineth { from { left: -12.5%; } to { left: 0 ;} }
`

export default Styling
