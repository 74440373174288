const getEventDate = (date) => {
  const [year, month, day] = date.split('-')
  const thisYear = new Date().toISOString().slice(0, 4)
  let showYear = ''
  if (thisYear !== year) {
    showYear = year
  }
  return `${['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'][new Date(date).getDay()]} ${day}.${month}.${showYear}`
}

export default getEventDate
