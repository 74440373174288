const getExtra = (extra, extraDate) => {
  let ret = ''
  const today = new Date(new Date().toISOString().slice(0, 10))
  const tomorrow = new Date(new Date(today.setDate(today.getDate())).toISOString().slice(0, 10))
  if ((extra && !extraDate) || (extra && extraDate && Date.parse(extraDate) - Date.parse(tomorrow) >= 0)) {
    return extra
  }
  return ret
}
export default getExtra
