import React from 'react'
import Styling from './Styling'
import useMenu from '../../../../customHooks/useMenu'

export default function Loading() {
  const { loading } = useMenu()
  return !loading ? null : (
    <Styling>
      <div id='loading' />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </Styling>
  )
}
