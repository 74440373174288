import React, { useEffect, useCallback, useState } from 'react'
import Table from 'react-bootstrap/Table'

import { Button, ButtonGroup } from 'react-bootstrap'

import { firestore } from '../../../../lib/firebase'
import useAuth from '../../../../customHooks/useAuth'
import useMenu from '../../../../customHooks/useMenu'

import Styling from './Styling'
import Structure from '../Structure'

export default function Dashboard() {
  const { currentUser } = useAuth()
  const { loading, setLoading } = useMenu()
  const [users, setUsers] = useState([])
  const { db, collection, getDocs, query, doc, setDoc, deleteDoc } = firestore

  const fetchData = useCallback(async () => {
    const u = []
    const q = query(collection(db, 'users'))
    await getDocs(q).then((querySnapshot) =>
      querySnapshot.forEach((doc) => {
        u.push({ ref: doc.ref, ...doc.data() })
      }),
    )
    setUsers(u)
    setLoading(false)
  }, [collection, db, getDocs, query, setLoading])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchData()
  }, [fetchData])

  const setRoles = async (ref, roles) => {
    await setDoc(doc(db, 'users', ref.id), { roles }, { merge: true })
    fetchData()
  }

  const toggleRole = (ref, oldroles, role) => {
    const roles = !oldroles.includes(role)
      ? [...oldroles, role]
      : [...oldroles.slice(0, oldroles.indexOf(role)), ...oldroles.slice(oldroles.indexOf(role) + 1, oldroles.length)]
    setRoles(ref, roles)
  }

  const deleteUser = async (ref) => {
    if (window.confirm('Wirklich löschen?')) {
      await deleteDoc(doc(db, 'users', ref.id))
      fetchData()
    }
  }

  // console.log(users)

  return (
    <Structure>
      <Structure.ContentHeader>
        <Structure.Section>Benutzer Administration</Structure.Section>
        <Structure.Interaction> </Structure.Interaction>
      </Structure.ContentHeader>
      <Structure.Content>
        <Styling>
          <Styling.Card>
            {loading ? (
              'Loading...'
            ) : (
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Name / Email</th>
                    <th>Rechte</th>
                    <th>Aktion</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map(({ ref, displayName, email, roles }) => (
                    <tr key={email}>
                      <td>
                        {displayName}
                        <br />
                        {email}
                      </td>
                      <td style={{ width: '384px' }}>
                        {roles.length === 0 ? (
                          <>&nbsp;</>
                        ) : (
                          <ButtonGroup aria-label="Actions">
                            <Button
                              variant={`${roles.includes('news') ? '' : 'outline-'}primary`}
                              onClick={() => toggleRole(ref, roles, 'news')}
                            >
                              News
                            </Button>
                            <Button
                              variant={`${roles.includes('events') ? '' : 'outline-'}primary`}
                              onClick={() => toggleRole(ref, roles, 'events')}
                            >
                              Events
                            </Button>
                            <Button
                              variant={`${roles.includes('organizers') ? '' : 'outline-'}primary`}
                              onClick={() => toggleRole(ref, roles, 'organizers')}
                            >
                              Veranstalter
                            </Button>
                            <Button
                              variant={`${roles.includes('faqs') ? '' : 'outline-'}primary`}
                              onClick={() => toggleRole(ref, roles, 'faqs')}
                            >
                              FAQs
                            </Button>
                            <Button
                              variant={`${roles.includes('users') ? '' : 'outline-'}primary`}
                              onClick={() => toggleRole(ref, roles, 'users')}
                              disabled={currentUser.email === email}
                            >
                              Benutzer
                            </Button>
                            {/* <Button
                              className="mx-2"
                              variant={`${roles.includes('settings') ? '' : 'outline-'}primary`}
                              onClick={() => toggleRole(ref, roles, 'settings')}
                            >
                              Einstellungen
                            </Button> */}
                          </ButtonGroup>
                        )}
                      </td>
                      <td style={{ width: '216px' }}>
                        {roles.length === 0 && (
                          <Button
                            className="mb-2"
                            style={{ width: '200px' }}
                            variant="success"
                            onClick={() => setRoles(ref, ['dashboard'])}
                          >
                            Account aktivieren
                          </Button>
                        )}
                        <Button
                          style={{ width: '200px' }}
                          variant="danger"
                          onClick={() => deleteUser(ref)}
                          disabled={currentUser.email === email}
                        >
                          Account löschen
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Styling.Card>
        </Styling>
      </Structure.Content>
    </Structure>
  )
}
