import { useEffect, useState } from 'react'

export default function useColorScheme() {
  const [colorScheme, setColorScheme] = useState('light')
  const modeChange = e => setColorScheme(e.matches ? 'dark' : 'light')

  useEffect(() => {
    if (window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setColorScheme('dark')
      }
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', modeChange)
    }
    return () => {
      if (window.matchMedia) {
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', modeChange)
      }
    }
  }, [colorScheme])

  return colorScheme
}
