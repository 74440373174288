import React from 'react'
import { Button } from 'react-bootstrap'

import { MdAdd as IconAdd, MdDelete as IconDelete } from 'react-icons/md'

import Structure from '../Structure'

function ContentHeader({ newsId, deleteNews, setCreateModalShow }) {
  return (
    <Structure.ContentHeader>
      <Structure.Section>News</Structure.Section>
      <Structure.Interaction>
        <Button variant="outline-primary" onClick={() => setCreateModalShow(true)} size="sm">
          <IconAdd /> News erstellen
        </Button>
        {newsId && (
          <Button
            className="mx-3"
            variant={newsId ? 'outline-danger' : 'outline-dark'}
            onClick={() => deleteNews()}
            size="sm"
            disabled={!newsId}
          >
            <IconDelete /> News löschen
          </Button>
        )}
      </Structure.Interaction>
    </Structure.ContentHeader>
  )
}

export default ContentHeader
