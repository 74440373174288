import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const SuperHeaderTitle = styled(Link)`
  position: fixed;
  top: 0;
  left: 0;
  height: 450px;
  right: 0;
  z-index: 0;
  padding-top: 140px;
  transition: opacity 0.3s;
  opacity: ${(theme) => (theme['data-scrolltop'] > 100 ? 0 : 1)};
  color: #000;
  text-decoration: none;

  &:hover {
    color: #000;
  }

  @media (min-width: 640px) {
    padding-top: 260px;
  }

  & > div {
    text-align: center;
    font-size: 22px;

    & > img {
      width: 80%;
      max-width: 320px;
    }
    // width="320" height="82"

    @media (min-width: 640px) {
      font-size: 28px;
    }
  }
`

export const LogoWrapper = styled.div`
  position: fixed;
  top: 14px;
  left: -10px;
  height: 0;
  right: 0;
  z-index: 120;

  & > a {
    display: block;
    margin: auto;
    width: 140px;
    height: 100px;
    transition: all 0.3s;
    @media (min-width: 640px) {
      width: ${(theme) => (theme['data-scrolltop'] > 100 ? 140 : 260)}px;
      height: ${(theme) => (theme['data-scrolltop'] > 100 ? 100 : 180)}px;
    }

    & > svg {
      display: block;
      margin: auto;
      width: 160px;
      transition: all 0.3s;
      height: 100px;
      @media (min-width: 640px) {
        width: ${(theme) => (theme['data-scrolltop'] > 100 ? 160 : 305)}px;
        height: ${(theme) => (theme['data-scrolltop'] > 100 ? 100 : 180)}px;
      }
    }
  }
`

export const ContentSection = styled.section`
  background: ${({ title }) => (title === 'Kontakt' ? '#000' : '#fff')};
  position: relative;
  padding: 80px 15px;

  @media (min-width: 768px) {
    padding: 80px 30px;
  }
  & > div {
    max-width: 1000px;
    margin: 0 auto;

    &:before {
      content: ${({ title }) => `"${title}"`};
      color: ${({ title }) => (title === 'Kontakt' ? '#fff' : '#000')};
      position: absolute;
      top: 20px;
      left: 0;
      height: 60px;
      right: 0;
      text-align: center;
      text-transform: uppercase;
      font-size: 35px;
      /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75), -1px -1px 2px rgba(0, 0, 0, 0.75),
        1px -1px 2px rgba(29, 79, 45, 0.75), -1px 1px 2px rgba(0, 0, 0, 0.75); */
      font-weight: 700;
      // letter-spacing: 3px;
      @media (min-width: 640px) {
        font-size: 40px;
      }
    }
  }
  &:first-of-type {
    margin-top: 15px;
    @media (min-width: 768px) {
      margin-top: 0;
    }
  }

`

export const Imprint = styled.div`
  color: white;
  text-align: center;
  background: black;
`

const Wrapper = styled.div`
  background: white; //black;
  z-index: 100;
`

export default Wrapper
