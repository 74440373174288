import React from 'react'
import Styling from './Styling'
import useMenu from '../../../../customHooks/useMenu'
import useAuth from '../../../../customHooks/useAuth'
import { VscChromeClose } from 'react-icons/vsc'

export default function Header() {
  const { error, setError, menuOpen, setMenuOpen } = useMenu()
  const { currentUser } = useAuth()

  return (
    <>
      <Styling>
        <Styling.MenuToggle
          menuOpen={menuOpen}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <div />
          <div />
          <div />
        </Styling.MenuToggle>
        <Styling.Logo />
        {currentUser && (
          <>
            <Styling.Name photoURL={currentUser.photoURL}>
              {currentUser.displayName || currentUser.email}
            </Styling.Name>
            {currentUser.photoURL && (
              <Styling.Image photoURL={currentUser.photoURL} />
            )}
          </>
        )}
      </Styling>
      <Styling.Error hidden={error === ''}>
        {error}
        <button type='button' onClick={() => setError('')}>
          <VscChromeClose />
        </button>
      </Styling.Error>
    </>
  )
}
