import React, { useState, useEffect } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'

import { BsCalendarEvent as IconCalendar, BsArchiveFill as IconEvent } from 'react-icons/bs'

import slugify from '../../../../lib/slugify'

function CreateEventModal({ onHide, show, createNews }) {
  const [label, setLabel] = useState('')
  const [date, setDate] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [slug, setSlug] = useState('')

  useEffect(() => {
    setSlug(slugify(`${date}-${label}`))
  }, [label, date])

  useEffect(() => {
    if (show) {
      setLabel('')
      setDate('')
      setSlug('')
    }
  }, [show])

  const handleCreate = (e) => {
    e.preventDefault()
    onHide()
    createNews(label, date, dateEnd, slug)
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-create-event" centered>
      <form onSubmit={handleCreate}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-create-event">News erstellen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text style={{ width: '150px' }}>
              <IconCalendar />
              &nbsp;Datum von *
            </InputGroup.Text>
            <Form.Control
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              // min={new Date().toISOString().substring(0, 10)}
              required
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text style={{ width: '150px' }}>
              <IconCalendar />
              &nbsp;Datum bis
            </InputGroup.Text>
            <Form.Control
              type="date"
              value={dateEnd}
              onChange={(e) => setDateEnd(e.target.value)}
              // min={new Date().toISOString().substring(0, 10)}
            />
          </InputGroup>
          <div>Wird kein Enddatum angegeben, so wird die Kachel dauerhaft angezeigt.</div>
          <hr />
          <InputGroup className="mb-3">
            <InputGroup.Text style={{ width: '150px' }}>
              <IconEvent />
              &nbsp;Label *
            </InputGroup.Text>
            <Form.Control required placeholder="" value={label} onChange={(e) => setLabel(e.target.value)} />
          </InputGroup>
          <hr />
          {`${window.location.origin}/news/${slug}`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={onHide}>
            abbrechen
          </Button>
          <Button variant="primary" size="sm" type="submit">
            speichern
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}

export default CreateEventModal
