import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'

import { MdAdd as IconAdd, MdDelete as IconDelete, MdContentCopy as IconCopy } from 'react-icons/md'

import Structure from '../Structure'

function ContentHeader({ eventId, deleteEvent, copyEvent, setCreateModalShow, addAct }) {
  return (
    <Structure.ContentHeader>
      <Structure.Section>Events</Structure.Section>
      <Structure.Interaction>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div>
            <Button variant="outline-primary" onClick={() => setCreateModalShow(true)} size="sm">
              <IconAdd /> Neues Event erstellen
            </Button>
            {eventId && (
              <ButtonGroup className="mx-3">
                <Button variant="outline-primary" onClick={copyEvent} size="sm" disabled={eventId.endsWith('-copy')}>
                  <IconCopy /> Dieses Event kopieren
                </Button>
                <Button
                  variant={eventId ? 'outline-danger' : 'outline-dark'}
                  onClick={deleteEvent}
                  size="sm"
                  disabled={!eventId}
                >
                  <IconDelete /> Dieses Event löschen
                </Button>
              </ButtonGroup>
            )}
          </div>
          <div>
            {eventId && (
              <>
                <Button variant="outline-success" onClick={addAct} size="sm">
                  <IconAdd /> Act hinzufügen
                </Button>
              </>
            )}
          </div>
        </div>
      </Structure.Interaction>
    </Structure.ContentHeader>
  )
}

export default ContentHeader
