import React, { useEffect, useState, useMemo, useCallback } from 'react'

import months from './months'

import { firestore, storage } from '../../../../lib/firebase'
import Structure from '../Structure'
import Styling from './Styling'

import ContentHeader from './ContentHeader'
import CreateNewsModal from './CreateNewsModal'
import NewsList from './NewsList'
import NewsItem from './NewsItem'

function News() {
  const { db, collection, getDocs, setDoc, doc, deleteDoc } = firestore
  const { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } = storage

  const [isLoading, setIsLoading] = useState(true)
  const [createModalShow, setCreateModalShow] = useState(false)

  const [news, setNews] = useState([])
  const [newsId, setNewsId] = useState()

  const [label, setLabel] = useState('')
  const [date, setDate] = useState('')
  const [dateEnd, setDateEnd] = useState('')
  const [online, setOnline] = useState(false)
  const [volta, setVolta] = useState(false)
  const [garden, setGarden] = useState(false)
  const [victoria, setVictoria] = useState(false)
  const [htmlContent, setHtmlContent] = useState('')
  const [images, setImages] = useState([])

  const ready = useMemo(() => label && date && (volta || garden || victoria), [label, date, volta, garden, victoria])

  const labels = useMemo(() => {
    const labs = ['Vergangen']
    news
      .map((n) => n.monthYear)
      .forEach((monthYear) => {
        if (labs.indexOf(monthYear) === -1) {
          labs.push(monthYear)
        }
      })
    return labs
  }, [news])

  const getEventsMonthYear = useCallback((date) => {
    if (Date.parse(date) - Date.parse(new Date()) < 0) {
      return 'Vergangen'
    }
    const year = date.split('-')[0]
    const month = months[parseInt(date.split('-')[1], 10)]
    return `${month} ${year}`
  }, [])

  const getNews = useCallback(async () => {
    const res = []
    await getDocs(collection(db, 'news')).then((querySnapshot) =>
      querySnapshot.forEach((doc) => {
        const data = {
          date: '', // immer leer initialisieren
          dateEnd: '',
          begin: '',
          online: false,
          ...doc.data(),
        }
        res.push({ id: doc.id, ...data })
      }),
    )
    return res
      .sort((a, b) =>
        a.date === b.date ? (a?.begin || '').localeCompare(b?.begin || '') : a.date.localeCompare(b.date),
      )
      .map((event) => ({
        ...event,
        monthYear: getEventsMonthYear(event.date),
      }))
  }, [collection, db, getDocs, getEventsMonthYear])

  const save = useCallback(
    async (props) => {
      let toSave = {
        label,
        date,
        dateEnd,
        online,
        htmlContent,
        volta,
        garden,
        victoria,
        images,
      }
      if (props?.what) {
        toSave = { ...toSave, ...props.what }
      }
      toSave.lastUpdate = new Date().toISOString()
      Object.keys(toSave).forEach((key) => {
        if (toSave[key] === '' || toSave[key] === null || toSave[key] === undefined) {
          delete toSave[key]
        }
      })
      // console.log('gespeichert', toSave)
      await setDoc(doc(db, 'news', newsId), toSave, { merge: true })
      setNews(await getNews())
    },
    [label, date, dateEnd, online, htmlContent, volta, garden, victoria, images, setDoc, doc, db, newsId, getNews],
  )

  const upload = useCallback(
    (e) => {
      const file = e.target.files[0]
      const metadata = { contentType: 'image/jpeg' }
      const reference = `images/${newsId}/${file.name.replace(/[^a-zA-Z0-9]/g, '')}`

      /* console.log(actindex, file, metadata, reference)
      return */
      const storageRef = ref(getStorage(), reference)
      const uploadTask = uploadBytesResumable(storageRef, file, metadata)

      uploadTask.on(
        'state_changed',
        () => {},
        (error) => console.error(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((src) => {
            save({ what: { images: [{ src, reference }] } })
          })
        },
      )
    },
    [newsId, getDownloadURL, getStorage, ref, save, uploadBytesResumable],
  )

  const delImage = useCallback(
    async (idx) => {
      const img = images
      const { reference } = img[0]
      const storage = getStorage()
      const desertRef = ref(storage, reference)
      // setLoading(true)
      deleteObject(desertRef)
        .then(() => {})
        .catch(() => {
          console.error('Could not delete image with reference', reference)
        })

      save({ what: { images: [] } })
    },
    [deleteObject, getStorage, images, ref, save],
  )

  const publish = useCallback(
    (check) => {
      setOnline(check)
      save({ what: { online: check } })
    },
    [save],
  )

  const createNews = useCallback(
    async (l, d, de, id) => {
      try {
        await setDoc(doc(db, 'news', id), { label: l, date: d, dateEnd: de }, { merge: true })
        setNews(await getNews())
        setNewsId(id)
      } catch (e) {
        console.error('Error adding document: ', e)
      }
    },
    [db, doc, getNews, setDoc],
  )

  const deleteNews = useCallback(async () => {
    if (
      !ready ||
      window.confirm(
        `Bist du sicher, dass du folgende News löschen willst?\n >>> ${label} <<<\nDies kann nicht rückgängig gemacht werden!`,
      )
    ) {
      const storage = getStorage()
      const currentNews = news.find((n) => n.id === newsId)
      if ((currentNews.images || []).length > 0) {
        currentNews.images.forEach(({ reference }) => deleteObject(ref(storage, reference)))
      }
      await deleteDoc(doc(db, 'news', newsId))
      setNewsId()
      setNews(await getNews())
    }
  }, [db, deleteDoc, deleteObject, doc, getNews, getStorage, label, news, newsId, ready, ref])

  useEffect(() => {
    const event = news.find((n) => n.id === newsId)
    if (event) {
      setLabel(event.label || '')
      setDate(event.date || '')
      setDateEnd(event.dateEnd || '')
      setOnline(event.online || false)
      setVolta(event.volta || false)
      setGarden(event.garden || false)
      setVictoria(event.victoria || false)
      setHtmlContent(event.htmlContent || '')
      setImages(event.images || [])
    }
  }, [news, newsId])

  useEffect(() => {
    const init = async () => {
      setNews(await getNews())
      setIsLoading(false)
    }
    init()
  }, [getNews])

  return isLoading ? null : (
    <>
      <Structure>
        <ContentHeader
          newsId={newsId}
          deleteNews={deleteNews}
          setCreateModalShow={setCreateModalShow}
          addAct={() => {}}
        />
        <Structure.Content>
          <Styling>
            <NewsList news={news} labels={labels} newsId={newsId} setNewsId={setNewsId} />
            <NewsItem
              newsId={newsId}
              label={label}
              date={date}
              dateEnd={dateEnd}
              volta={volta}
              garden={garden}
              victoria={victoria}
              online={online}
              ready={ready}
              htmlContent={htmlContent}
              setLabel={setLabel}
              setDate={setDate}
              setDateEnd={setDateEnd}
              setVolta={setVolta}
              setGarden={setGarden}
              setVictoria={setVictoria}
              publish={publish}
              setHtmlContent={setHtmlContent}
              upload={upload}
              delImage={delImage}
              save={save}
              images={images}
              setImages={setImages}
            />
          </Styling>
        </Structure.Content>
      </Structure>
      <CreateNewsModal show={createModalShow} createNews={createNews} onHide={() => setCreateModalShow(false)} />
    </>
  )
}

export default News
